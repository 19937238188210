// src/components/ProcessWorkflowsTab.tsx

import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Snackbar,
  Typography,
  TextField,
  InputAdornment,
  DialogContentText,
  MenuItem,
  Chip,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {
  FixedSizeList as VirtualizedList,
  ListChildComponentProps,
} from "react-window";

import { ICustomer } from "../utils/customer";
import { IWorkflow } from "../types/general"; // Ensure correct import path
import api from "../utils/api";
import ErrorBoundary from "./ErrorBoundary"; // Ensure correct import path
import { generateDataFromFields } from "../utils/processStreetData";

// Import DatePicker components
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import WorkflowCard from "./WorkflowCard";

interface ProcessWorkflowsTabProps {
  customer: ICustomer;
  spouseContact?: ICustomer;
  familyContact?: ICustomer;
  primaryContact?: ICustomer;
}

const ProcessWorkflowsTab: React.FC<ProcessWorkflowsTabProps> = ({
  customer,
  spouseContact,
  familyContact,
  primaryContact,
}) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<IWorkflow | null>(
    null
  );
  const [workflows, setWorkflows] = useState<IWorkflow[]>([]);
  const [loading, setLoading] = useState(false);
  const [launching, setLaunching] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [workflowRuns, setWorkflowRuns] = useState<any[]>([]);

  const fetchWorkflowRuns = async () => {
    setLoading(true);
    let cfsClientId=customer.new_cfs_client_id||customer.cfs_client_id
    try {
      const response = await api.get<any[]>(
        `/process-street/workflows-cfs?cfsClientIds=${cfsClientId}`
      );
      setWorkflowRuns(response.data.filter((e)=>e.status!=='Completed'));
    } catch (error: any) {
      console.error(
        "Error fetching workflow runs:",
        error.response?.data || error.message
      );
      setSnackbarMessage("Error fetching workflow runs");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkflowRuns();
  }, [customer.cfs_client_id]);

  // Added state variables for assigned users and due date
  const [assignedUsers, setAssignedUsers] = useState<
    { email: string; username: string }[]
  >([]);
  const [dueDate, setDueDate] = useState<Date | null>(null); // Store due date as a Date object
  const [availableUsers, setAvailableUsers] = useState<
    { email: string; username: string }[]
  >([]);

  // Debounce search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Function to open the modal and fetch workflows
  const handleOpen = async () => {
    setOpen(true);
    setLoading(true);

    try {
      const response = await api.get<IWorkflow[]>("/process-street/workflows");
      setWorkflows(response.data);
    } catch (error: any) {
      console.error(
        "Error fetching workflows:",
        error.response?.data || error.message
      );
      setSnackbarMessage("Error fetching workflows");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Function to close the modal
  const handleClose = () => {
  
    setOpen(false);
    setSearchTerm("");
    setDebouncedSearchTerm("");
  };

  // Function to close the confirmation dialog
  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setSelectedWorkflow(null);
    // Reset assigned users and due date when closing the dialog
    setAssignedUsers([]);
    setDueDate(null);
  };

  // Function to open the confirmation dialog
  const handleConfirmOpen = async (workflow: IWorkflow) => {
    setSelectedWorkflow(workflow);
    setConfirmOpen(true);

    // Fetch available users when the confirmation dialog opens
    try {
      const userResponse = await api.get("/process-street/users");
      setAvailableUsers(userResponse.data);
    } catch (error: any) {
      console.error(
        "Error fetching users:",
        error.response?.data || error.message
      );
      setSnackbarMessage("Error fetching users");
      setSnackbarOpen(true);
    }
  };

  // Function to close the snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Function to handle workflow selection and open confirmation dialog
  const handleWorkflowSelect = (workflow: IWorkflow) => {
    handleConfirmOpen(workflow);
  };

  // Function to confirm and create a workflow run
  const handleConfirmLaunch = async () => {
    if (!selectedWorkflow) return;
    setLaunching(true);

    try {
      const formFieldsResponse = await api.get(
        `/process-street/workflows/${selectedWorkflow.id}/fields`
      );
      const formFields = formFieldsResponse.data.fields.fields;
      const generatedFields = generateDataFromFields(
        customer,
        spouseContact,
        familyContact,
        primaryContact
      );

      const fieldsData = formFields
        .map((field: any) => {
          const fieldId = field.id;
          const generatedField = generatedFields.find(
            (item) => item.id === fieldId
          ); // Match by ID
          const value = generatedField ? generatedField.value : ""; // Get the value or set an empty string if not found

          return {
            id: fieldId,
            value,
          };
        })
        .filter((item: any) => item.value !== "");

        const formattedDate = dueDate 
        ? dueDate.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })
        : "";

      const response = await api.post("/process-street/workflow-runs", {
        workflowId: selectedWorkflow.id,
        name: `${customer.first_name+" "+ customer.last_name || customer.full_name} | ${selectedWorkflow.name} | ${formattedDate}`,
        fields: fieldsData,
        assignees: assignedUsers.map((user) => user.email),
        dueDate: dueDate ? dueDate.toISOString() : undefined,
        cfsClientId: customer.new_cfs_client_id || customer.cfs_client_id,
      });

      console.log("Workflow run created successfully:", response.data);
      setSnackbarMessage("Workflow run created successfully!");
      fetchWorkflowRuns();
      setSnackbarOpen(true);
      handleClose();
    } catch (error: any) {
      console.error(
        "Error creating workflow run:",
        error.response?.data || error.message
      );
      setSnackbarMessage("Failed to create workflow run");
      setSnackbarOpen(true);
    } finally {
      setLaunching(false);
      handleConfirmClose();
    }
  };

  // Function to handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Memoized filtered workflows based on debounced search term
  const filteredWorkflows = useMemo(() => {
    return workflows.filter(
      (workflow) =>
        (workflow.name?.toLowerCase() || "").includes(
          debouncedSearchTerm.toLowerCase()
        ) ||
        (workflow.description?.toLowerCase() || "").includes(
          debouncedSearchTerm.toLowerCase()
        )
    );
  }, [workflows, debouncedSearchTerm]);

  // Row renderer for react-window
  const Row = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const workflow = filteredWorkflows[index];
      const isEven = index % 2 === 0; // For alternating background colors

      return (
        <div
          style={{
            ...style,
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)", 
            padding: "8px 0",
            backgroundColor: isEven
              ? "rgba(255, 255, 255, 0.05)"
              : "transparent",
          }}
          key={workflow.id}
        >
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleWorkflowSelect(workflow)}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)", // Slightly lighter on hover
                },
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1" fontWeight="bold">
                    {workflow.name || "Unnamed Workflow"}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {workflow.description || "(No Description)"}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </div>
      );
    },
    [filteredWorkflows, handleWorkflowSelect]
  );

  return (
    <ErrorBoundary>
      <Box>
        {/* Run Workflow Button */}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
          disabled={loading}
          sx={{ mb: 2 }}
        >
          Run Workflow
        </Button>
        {loading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      ) : workflowRuns.length === 0 ? (
        <Typography>No workflows found for this client.</Typography>
      ) : (
        workflowRuns.map((workflow) => (
          <WorkflowCard
            key={workflow.id}
            workflow={workflow}
            dueDate={new Date(workflow.dueDate)}
            workflowUrl={workflow.workflowRunUrl || ""}
          />
        ))
      )}
        {/* Modal Dialog */}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>Select a Workflow to Run</DialogTitle>
          <DialogContent>
            {/* Search Bar */}
            <Box mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search Workflows..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {loading ? (
              // Loading Indicator
              <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress />
              </Box>
            ) : filteredWorkflows.length === 0 ? (
              // No Workflows Message
              <Typography>No workflows found.</Typography>
            ) : (
              // Virtualized List of Workflows with react-window
              <VirtualizedList
                height={600} // Adjust based on your modal size
                itemCount={filteredWorkflows.length}
                itemSize={80} // Adjust based on item height
                width="100%"
              >
                {Row}
              </VirtualizedList>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={loading}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmOpen}
          onClose={handleConfirmClose}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="confirm-dialog-title">Confirm Launch</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-dialog-description">
              Are you sure you want to launch the workflow{" "}
              <strong>{selectedWorkflow?.name || "Unnamed Workflow"}</strong>?
            </DialogContentText>

            {/* Due Date Picker */}
            <Box mt={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label=""
                  value={dueDate}
                  onChange={(newValue) => setDueDate(newValue)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      placeholder: "Due Date",
                      InputLabelProps: { shrink: false },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>

            {/* User Assignment */}
            <Box mt={2}>
              <Autocomplete
                multiple
                options={availableUsers}
                getOptionLabel={(option) =>
                  `${option.username} (${option.email})`
                }
                onChange={(event, newValue) => {
                  setAssignedUsers(newValue);
                }}
                value={assignedUsers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Assign Users"
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={`${option.username} (${option.email})`}
                      {...getTagProps({ index })}
                      sx={{ margin: "2px" }}
                    />
                  ))
                }
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    flexWrap: "wrap",
                  },
                  "& .MuiChip-root": {
                    maxWidth: "100%",
                  },
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmClose}
              color="primary"
              disabled={launching}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmLaunch}
              color="secondary"
              disabled={launching}
            >
              {launching ? <CircularProgress size={24} /> : "Launch"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for Feedback */}
        <Snackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default ProcessWorkflowsTab;
