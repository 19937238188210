import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  IconButton,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { useQueryClient } from "@tanstack/react-query";

import { useSyncCustomers } from "../hooks/useSyncCustomers";
import StaffManagement from "../components/StaffManagement";
import api from "../utils/api";
import { ServiceTypes } from "../types/general";
import {
  useGetAuthenticatedServiceLink,
  useGetUserServices,
} from "../hooks/useGetUserServices";
import { useGetUser } from "../hooks/useUser";

// Define TypeScript interfaces for type safety
interface ConnectedAccount {
  name: string;
  icon: string;
  type: ServiceTypes;
}

// Define your services
const services: ConnectedAccount[] = [
  {
    name: "Salesforce",
    icon: "/assets/Logos/salesforce-icon.png",
    type: "SALESFORCE",
  },
  {
    name: "Process Street",
    icon: "/assets/Logos/process-street-icon.png",
    type: "PROCESS_STREET",
  },
  {
    name: "Harvest",
    icon: "/assets/Logos/harvest-icon.png",
    type: "HARVEST",
  },
  {
    name: "QuickBooks",
    icon: "/assets/Logos/qb.png",
    type: "QUICKBOOKS",
  },
  {
    name: "Trello",
    icon: "/assets/Logos/Trello.png",
    type: "TRELLO",
  },
  {
    name: "Ring Central",
    icon: "/assets/Logos/ringcentral.png",
    type: "RINGCENTRAL",
  },
];

const Settings: React.FC = () => {
  // Snackbar States
  const { data: user } = useGetUser();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // React Query Client
  const queryClient = useQueryClient();

  // Fetch connected services using your custom hook
  const {
    data: connectedServices,
    isLoading: servicesLoading,
    isError: servicesError,
  } = useGetUserServices();

  // Get authenticated service link using your custom hook
  const { mutateAsync: getAuthenticateServiceLink } =
    useGetAuthenticatedServiceLink();

  // Sync Contacts Functionality
  const { mutateAsync: syncContacts, status } = useSyncCustomers();
  const isLoading = status === "pending";

  // Check if a service is connected
  const isServiceConnected = (serviceType: ServiceTypes) => {
    return connectedServices?.some(
      (service: any) => service.type === serviceType
    );
  };

  const handleAuthenticate = async (serviceType: ServiceTypes) => {
    if (serviceType === "RINGCENTRAL") {
      const state = JSON.stringify({
        returnTo: "/settings",
        user_id: user?.id,
      });

      // Redirect directly to the RingCentral authenticate endpoint
      window.location.href = `api/ringcentral/authenticate?state=${encodeURIComponent(
        state
      )}`;
    } else {
      try {
        const state = JSON.stringify({
          returnTo: "/settings",
          user_id: user?.id,
          type: serviceType,
        });

        const { loginUrl } = await getAuthenticateServiceLink({
          type: serviceType,
          state,
        });

        window.location.href = loginUrl;
      } catch (error) {
        console.error(`Failed to authenticate with ${serviceType}`, error);
        setSnackbarMessage(`Failed to connect with ${serviceType}`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    }
  };

  const handleSyncRingCentralContacts = async () => {
    try {
      const userId = user?.id;
      setSnackbarMessage("RingCentral contacts is syncing");
      setIsButtonDisabled(true); // Disable the button
      setTimeout(() => {
        setIsButtonDisabled(false); // Re-enable the button after 15 minutes
      }, 15 * 60 * 1000); // 15 minutes in milliseconds
      
      await api.post(`/api/ringcentral/syncContactsBulk/${userId}`);
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Failed to sync RingCentral contacts", error);

      // Show error message
      setSnackbarMessage("Failed to sync RingCentral contacts");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };


  // Handle disconnection (disconnect)
  const handleDisconnect = async (serviceType: ServiceTypes) => {
    try {
      await api.post("/api/service/disconnect", { type: serviceType });
      // Invalidate the query to refetch connected services
      await queryClient.invalidateQueries({ queryKey: ["services"] });
      setSnackbarMessage(`${serviceType} disconnected successfully`);
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error(`Failed to disconnect from ${serviceType}`, error);
      setSnackbarMessage(`Failed to disconnect from ${serviceType}`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  // Handle syncing contacts
  const handleSyncContacts = async () => {
    try {
      await syncContacts();
      setSnackbarMessage("Contacts synced successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Failed to sync contacts", error);
      setSnackbarMessage("Failed to sync contacts");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  // Handler to close the snackbar
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  if (!user) {
    return <Typography>Loading user data...</Typography>;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexGrow: 1,
        overflow: "auto",
        flexDirection: "column",
        p: 3,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      <Divider sx={{ width: "100%", mb: 3 }} />

      {/* Non-admin users only see the Connected Accounts Section */}
      <Typography variant="h5" gutterBottom>
        {user.is_admin ? "Connected Accounts" : "Your Connected Accounts"}
      </Typography>
      {servicesLoading ? (
        <Typography>Loading connected services...</Typography>
      ) : servicesError ? (
        <Typography>Error loading services.</Typography>
      ) : (
        <List>
          {services.map((service, index) => {
            const connected = isServiceConnected(service.type);
            return (
              <ListItem
                key={index}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <ListItemIcon>
                  <img
                    src={service.icon}
                    alt={service.name}
                    style={{ width: 30, height: 30, marginInline: 6 }}
                  />
                </ListItemIcon>
                <ListItemText primary={service.name} />
                <IconButton
                  edge="end"
                  aria-label={connected ? "disconnect" : "connect"}
                  onClick={() =>
                    connected
                      ? handleDisconnect(service.type)
                      : handleAuthenticate(service.type)
                  }
                >
                  {connected ? <LinkOffIcon /> : <LinkIcon />}
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      )}

      <Divider sx={{ width: "100%", my: 3 }} />
      {user.is_admin && (
        <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSyncContacts}
            disabled={isLoading}
          >
            {isLoading ? "Syncing Contacts..." : "Sync Contacts"}
          </Button>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSyncRingCentralContacts}
          disabled={isButtonDisabled}
        >
          {isButtonDisabled?'Syncing...' : "Sync RingCentral Contacts"}
        </Button>
      </Box>
      {/* Admin-specific section */}
      {user?.is_admin && (
        <>
          <StaffManagement />
        </>
      )}

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
