import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { TextField, Typography, Box } from "@mui/material";
import api from "../utils/api";
import { Payable } from "../types/general";

const formatCurrency = (value: any) => {
  const num = Number(value);
  if (value == null || isNaN(num)) return "N/A";
  return Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(num);
};

const formatDate = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime()) ? date.toLocaleDateString("en-US") : "N/A";
};

const AgedPayableDetails: React.FC = () => {
  const [payableDetails, setPayableDetails] = useState<Payable[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [search, setSearch] = useState<string>("");

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchPayableDetails = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await api.get("/api/quickbooks/quickbooks/stored-aged-payable-detail", {
          params: {
            search,
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
          }
        });

        setPayableDetails(response.data.records || []);
        setTotalCount(response.data.total || 0);
      } catch (err) {
        console.error("Error fetching payable details:", err);
        setError("Failed to load payable details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchPayableDetails();
  }, [search, paginationModel]);

  const columns: GridColDef[] = [
    { field: "vendorName", headerName: "Vendor Name", flex: 1, sortable: true, renderCell: (params: any) => params.value || "N/A" },
    { field: "txnType", headerName: "Transaction Type", flex: 1, sortable: true, renderCell: (params: any) => params.value || "N/A" },
    { field: "docNum", headerName: "Document Number", flex: 1, sortable: true, renderCell: (params: any) => params.value || "N/A" },
    {
      field: "txnDate",
      headerName: "Transaction Date",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => formatDate(params.value),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => formatDate(params.value),
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => formatCurrency(params.value),
    },
    {
      field: "openBalance",
      headerName: "Open Balance",
      type: "number",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => formatCurrency(params.value),
    },
    {
      field: "pastDue",
      headerName: "Past Due (Days)",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        const val = params.value;
        const numVal = Number(val);
        if (!isNaN(numVal)) {
          return `${numVal} days`;
        } else {
          return val || "N/A";
        }
      },
    },
  ];

  return (
    <>
      {error && (
        <Typography variant="body1" color="error" mb={2}>
          {error}
        </Typography>
      )}
      <Box mb={2}>
        <TextField
          variant="outlined"
          label="Search"
          value={search}
          onChange={(e) => {
            setPaginationModel({ ...paginationModel, page: 0 }); 
            setSearch(e.target.value);
          }}
          size="small"
        />
      </Box>
      <DataGrid
        rows={payableDetails}
        columns={columns}
        loading={loading}
        rowCount={totalCount}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
};

export default AgedPayableDetails;
