import { useQuery, useMutation } from '@tanstack/react-query';
import api from '../utils/api';
import { ICustomer } from '../utils/customer';

interface GetProjectsByCodeInput {
  code: string;
}

interface CreateHarvestCustomerInput {
  customer: ICustomer;
}

export interface CreateHarvestProjectInput {
  customer: {
    id: string;
  };
  project: {
    client_id: number; 
    code:string;
    name: string;     
    is_active?: boolean;
    billable?: boolean;
    budget?: number | 0; 
    notes?: string;
    hourly_rate:number;
    starts_on:string | undefined;
    ends_on:string | undefined;
    user_ids:any
    task_ids:any
  };
}

// Fetch projects by code
export const getProjectsByCodeQuery = (props: GetProjectsByCodeInput) => ({
  queryKey: ['projectsByCode', props.code],
  queryFn: async () => (await api.get(`/api/service/harvest/code/${props.code}`)).data,
});

  // Helper function to format addresses
  const formatAddress = (address: any) => {
    if (!address) return "N/A";
    const { street = "", city = "", state = "", postalCode = "", country = "" } = address;
    return `${street}, ${city}, ${state} ${postalCode}, ${country}`;
  };

// Create a customer in Harvest
export const createHarvestCustomer = async (props: CreateHarvestCustomerInput,userId:string | undefined) => {
  const response = await api.post(`/api/service/harvest/createClient/${userId}`, {
    clientData: {
      customerId: props.customer.id,
      name: props.customer.full_name || props.customer.first_name+" "+ props.customer.last_name,
      is_active: true,
      address:formatAddress(props.customer.mailing_address)|| '',
    },
  });
  return response.data;
};

// Create a project in Harvest
export const createHarvestProject = async (props: CreateHarvestProjectInput,userId:string | undefined) => {
  const response = await api.post(`/api/service/harvest/CreateProject/${userId}`, {
    customerId: props.customer.id,
    project:props.project,
  });
  return response.data;
};


export const useFetchHarvestUsers = () => {
  return useQuery({
    queryKey: ["harvestUsers"],
    queryFn: async () => {
      const response = await api.get("/api/service/harvest/users");
      return response.data;
    },
  });
};
// Fetch user's assigned projects
export const fetchUserAssignments = async (userId: string|undefined) => {
  const response = await api.get(`/api/service/harvest/users/${userId}/assignments`);
  return response.data;
};

export const startTimer = async (userId: string | undefined, projectId: number, taskId: number) => {
  try {
    const response = await api.post(`/api/service/harvest/users/${userId}/time_entries/start`, {
      project_id: projectId,
      task_id: taskId,
    });
    return response.data;
  } catch (error: any) {
    console.error('Failed to start timer:', error.response?.data || error.message);
    throw error;
  }
};


// Stop timer
export const stopTimer = async (userId: string | undefined, timerId: number) => {
  try {
    const response = await api.patch(
      `/api/service/harvest/users/${userId}/time_entries/${timerId}/stop`
    );
    return response.data;
  } catch (error: any) {
    console.error('Failed to stop timer:', error.response?.data || error.message);
    throw error;
  }
};

export const restartTimer = async (userId: string | undefined, timerId: number) => {
  try {
    const response = await api.patch(
      `/api/service/harvest/users/${userId}/time_entries/${timerId}/restart`
    );
    return response.data;
  } catch (error: any) {
    console.error('Failed to restart timer:', error.response?.data || error.message);
    throw error;
  }
};



export const useFetchAllTasks = (userId: string|undefined) => {
  return useQuery({
    queryKey: ["harvestTasks"],
    queryFn: async () => {
      const response = await api.get(`/api/service/harvest/tasks/${userId}`);
      return response.data;
    },
  });
};

export const fetchTasksAssignedToProject = async (userId: string, projectId: number) => {
  const response = await api.get(`/api/service/harvest/users/${userId}/projects/${projectId}/tasks`);
  return response.data.task_assignments || [];
};
