import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { TextField, Typography, Box } from "@mui/material";
import api from "../utils/api";
import { Receivable } from "../types/general";

const formatCurrency = (value: any) => {
  const num = Number(value);
  if (value == null || isNaN(num)) return "N/A";
  return Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(num);
};

const formatDate = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime()) ? date.toLocaleDateString("en-US") : "N/A";
};

const AgedReceivables: React.FC = () => {
  const [data, setData] = useState<Receivable[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [search, setSearch] = useState<string>("");

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await api.get("/api/quickbooks/quickbooks/stored-aged-receivables", {
          params: { search, page: paginationModel.page + 1, pageSize: paginationModel.pageSize },
        });
        setData(response.data.records || []);
        setTotalCount(response.data.total || 0);
      } catch (err) {
        console.error("Error fetching receivables:", err);
        setError("Failed to load receivables. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [search, paginationModel]);

  const columns: GridColDef[] = [
    { field: "customerName", headerName: "Customer Name", flex: 1, sortable: true, renderCell: (params: any) => params.value || "N/A" },
    { field: "current", headerName: "Current", type: "number", flex: 1, sortable: true, renderCell: (params: any) => formatCurrency(params.value) },
    { field: "oneToThirtyDays", headerName: "1-30 Days", type: "number", flex: 1, sortable: true, renderCell: (params: any) => formatCurrency(params.value) },
    { field: "thirtyOneToSixtyDays", headerName: "31-60 Days", type: "number", flex: 1, sortable: true, renderCell: (params: any) => formatCurrency(params.value) },
    { field: "sixtyOneToNinetyDays", headerName: "61-90 Days", type: "number", flex: 1, sortable: true, renderCell: (params: any) => formatCurrency(params.value) },
    { field: "ninetyOneAndOver", headerName: "91+ Days", type: "number", flex: 1, sortable: true, renderCell: (params: any) => formatCurrency(params.value) },
    { field: "total", headerName: "Total", type: "number", flex: 1, sortable: true, renderCell: (params: any) => formatCurrency(params.value) },
    { field: "reportDate", headerName: "Report Date", flex: 1, sortable: true, renderCell: (params: any) => formatDate(params.value) },
  ];

  return (
    <>
      {error && (
        <Typography variant="body1" color="error" mb={2}>
          {error}
        </Typography>
      )}
      <Box mb={2}>
        <TextField
          variant="outlined"
          label="Search"
          value={search}
          onChange={(e) => {
            setPaginationModel({ ...paginationModel, page: 0 });
            setSearch(e.target.value);
          }}
          size="small"
        />
      </Box>
      <DataGrid
        rows={data}
        columns={columns}
        loading={loading}
        rowCount={totalCount}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
};

export default AgedReceivables;
