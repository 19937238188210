import React, { useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
  CardActions,
  Grid,
  useTheme,
  useMediaQuery,
  MenuItem,
  Divider,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ICustomer } from "../utils/customer";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getProjectsByCodeQuery,
  createHarvestCustomer,
  createHarvestProject,
  CreateHarvestProjectInput,
  useFetchHarvestUsers,
  useFetchAllTasks,
} from "../hooks/useHarvest";
import { useGetUser } from "../hooks/useUser";
import dayjs from "dayjs";

interface HarvestSectionProps {
  customer: ICustomer;
}

const HarvestSection: React.FC<HarvestSectionProps> = ({ customer }) => {
  const textValue = customer?.new_cfs_client_id || "";

  // Fetch Harvest projects
  const {
    data: projects,
    error: harvestError,
    isLoading: isHarvestLoading,
    refetch: refetchProjects,
  } = useQuery({
    queryKey: ["projectsByCode", textValue],
    queryFn: () => getProjectsByCodeQuery({ code: textValue }).queryFn(),
    enabled: Boolean(textValue),
  });

  // Fetch Harvest users
  const {
    data: usersData,
    isLoading: isUsersLoading,
    error: usersError,
  } = useFetchHarvestUsers();

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  // State for project fields
  const [hourlyRate, setHourlyRate] = useState<number>(0);
  const [startsOn, setStartsOn] = useState<string | undefined>(undefined);
  const [endsOn, setEndsOn] = useState<string | undefined>(undefined);
  const [isBillable, setIsBillable] = useState<boolean>(true);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  // State for notifications
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  // Handle modal open/close
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setProjectName("");
    setHourlyRate(0);
    setStartsOn(undefined);
    setEndsOn(undefined);
    setIsBillable(true);
    setSelectedUsers([]);
  };

  const handleSnackbarClose = () =>
    setSnackbar({ open: false, message: "", severity: "success" });

  const { data: user } = useGetUser();


  const { data: tasksData, isLoading: isTasksLoading } = useFetchAllTasks(user?.id);

  const createCustomerMutation = useMutation({
    mutationFn: async () => createHarvestCustomer({ customer }, user?.id),
    onSuccess: async (data) => {
      customer.harvest_client_id = data.harvest_client_id;
      try {
        await refetchProjects();
        setSnackbar({
          open: true,
          message: "Customer created successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Error refetching projects:", error);
        setSnackbar({
          open: true,
          message: "Failed to refresh projects.",
          severity: "error",
        });
      }
      handleCloseModal();
    },
    onError: (error: any) => {
      console.error("Error creating customer in Harvest:", error);
      setSnackbar({
        open: true,
        message: "Failed to create customer.",
        severity: "error",
      });
    },
  });

  const projectInput: CreateHarvestProjectInput = {
    customer: {
      id: customer.id,
    },
    project: {
      client_id: Number(customer.harvest_client_id),
      name: projectName,
      is_active: true,
      billable: isBillable,
      code: customer.new_cfs_client_id || customer.cfs_client_id || "",
      budget: 5000,
      notes: "This is a new project.",
      hourly_rate: hourlyRate,
      starts_on: startsOn,
      ends_on: endsOn,
      user_ids: selectedUsers.map((user) => user.id),
      task_ids: selectedTasks.map((task:any) => task.id),
    },
  };

  // Mutation for creating project in Harvest
  const createProjectMutation = useMutation({
    mutationFn: async () => createHarvestProject(projectInput, user?.id),
    onSuccess: async () => {
      try {
        await refetchProjects();
        setSnackbar({
          open: true,
          message: "Project created and users assigned successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Error refetching projects:", error);
        setSnackbar({
          open: true,
          message: "Failed to refresh projects.",
          severity: "error",
        });
      }
      handleCloseModal();
    },
    onError: (error: any) => {
      console.error("Error creating project in Harvest:", error);
      setSnackbar({
        open: true,
        message: "Failed to create project.",
        severity: "error",
      });
    },
  });

  // Handle create button click
  const handleCreate = () => {
    if (customer.harvest_client_id) {
      if (!projectName.trim()) {
        setSnackbar({
          open: true,
          message: "Project name cannot be empty.",
          severity: "error",
        });
        return;
      }
      createProjectMutation.mutate();
    } else {
      createCustomerMutation.mutate();
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      {/* Header with "+" button */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        mt={4}
      >
        <Typography variant="h5">Harvest Projects</Typography>
        <IconButton color="primary" onClick={handleOpenModal}>
          <AddIcon />
        </IconButton>
      </Box>

      {isHarvestLoading ? (
        <Grid container spacing={2}>
          {Array.from(new Array(6)).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <CircularProgress />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : harvestError ? (
        <Typography color="error">Error loading Harvest projects.</Typography>
      ) : projects && projects.length > 0 ? (
        <Grid container spacing={2}>
          {projects.map((project: any) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Card
                sx={{
                  "&:hover": {
                    boxShadow: 6,
                  },
                }}
              >
                <CardContent sx={{ paddingBottom: "16px" }}>
                  <Typography variant="h6" gutterBottom>
                    {project.name}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Box display="flex" alignItems="center" mb={1}>
                    <EventIcon sx={{ mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      <strong>Start Date:</strong> { dayjs(project.starts_on).format('DD/MM/YYYY') || "N/A"}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <AccessTimeIcon sx={{ mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      <strong>End Date:</strong> {dayjs(project.ends_on).format('DD/MM/YYYY') || "N/A"}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Budget:</strong> {project.budget || "N/A"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Hourly Rate:</strong> {project.hourly_rate || "N/A"}
                  </Typography>
                  {/* Display Assigned Users */}
                  {project.assigned_users &&
                    project.assigned_users.length > 0 && (
                      <Box mt={2}>
                        <Typography variant="subtitle2">
                          Assigned Users:
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          {project.assigned_users.map((user: any) => (
                            <Typography key={user.id} variant="body2">
                              {user.first_name} {user.last_name}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    )}

                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    href={`https://cuddyfinancialservices.harvestapp.com/projects/${project.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<OpenInNewIcon />}
                  >
                    Open in Harvest
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No Harvest projects found for this customer.</Typography>
      )}

      {/* Modal Dialog */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {customer.harvest_client_id
            ? "Create a New Harvest Project"
            : "Create Customer in Harvest"}
        </DialogTitle>
        <DialogContent>
          {customer.harvest_client_id ? (
            <>
              <TextField
                variant="filled"
                label="Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                variant="filled"
                label="Hourly Rate"
                type="number"
                value={hourlyRate}
                onChange={(e) => setHourlyRate(parseFloat(e.target.value) || 0)}
                fullWidth
                margin="normal"
              />
              <TextField
                variant="filled"
                label="Start Date"
                type="date"
                value={startsOn ? dayjs(startsOn).format('YYYY-MM-DD') : ''}
                onChange={(e) => setStartsOn(e.target.value)} // Captures in YYYY-MM-DD format
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                variant="filled"
                label="End Date"
                type="date"
                value={endsOn ? dayjs(endsOn).format('YYYY-MM-DD') : ''}
                onChange={(e) => setEndsOn(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                variant="filled"
                label="Is Billable"
                select
                value={isBillable ? "true" : "false"}
                onChange={(e) => setIsBillable(e.target.value === "true")}
                fullWidth
                margin="normal"
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              {/* User Selection */}
              <Autocomplete
                multiple
                options={usersData || []}
                getOptionLabel={(option: any) =>
                  `${option.first_name} ${option.last_name}`
                }
                loading={isUsersLoading}
                onChange={(event, value) => setSelectedUsers(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Assign Users"
                    placeholder="Select users to assign"
                    margin="normal"
                    fullWidth
                  />
                )}
                sx={{ marginTop: 2 }}
              />
                                <Autocomplete
                    multiple
                    options={tasksData || []}
                    getOptionLabel={(option:any) => option.name}
                    loading={isTasksLoading}
                    onChange={(event, value:any) => setSelectedTasks(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Assign Tasks"
                        placeholder="Select tasks to assign"
                        margin="normal"
                        fullWidth
                      />
                    )}
                    sx={{ marginTop: 2 }}
                  />
            </>
          ) : (
            <Typography>
              The customer does not have a Harvest Client ID. Would you like to
              create this customer in Harvest?
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            onClick={handleCreate}
            variant="contained"
            color="primary"
            disabled={
              (customer.harvest_client_id && !projectName) ||
              createCustomerMutation.status === "pending" ||
              createProjectMutation.status === "pending"
            }
          >
            {createCustomerMutation.status === "pending" ||
            createProjectMutation.status === "pending" ? (
              <CircularProgress size={24} />
            ) : customer.harvest_client_id ? (
              "Create Project"
            ) : (
              "Create Customer"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default HarvestSection;
