// src/routes/AppRoutes.tsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import Overview from "../pages/Overview";
import Customers from "../pages/Customers";
import Settings from "../pages/Settings";
import CustomerDetails from "../layouts/CustomerDetails";
import Authentification from "../pages/Auth";
import AuthCallback from "../utils/AuthCallback";
import HarvestTimeEntries from "../components/HarvestTimeEntries";
import HarvestProjectManagement from "../components/HarvestProjectManagement";
import AgedPayableDetails from "../components/AgedPayableDetails";
import AgedPayables from "../components/AgedPayables";
import AgedReceivableDetails from "../components/AgedReceivableDetails";
import AgedReceivables from "../components/AgedReceivables";
import { useTeamsContext } from "../hooks/useTeamsContext";
import { CircularProgress } from "@mui/material";
import MyAssignments from "../components/dashboard/components/MyAssignments";
import Profile from "../components/Profile";
import Analytics from "../components/dashboard/components/Analytics";

const AppRoutes: React.FC = () => {
  const context = useTeamsContext();
  if (context === null) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (context.inTeamsTab) {
    return (
      <Routes>
        <Route
          path="dashboard/customers/:customerId"
          element={<CustomerDetails />}
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/auth" element={<Authentification />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/" element={<Dashboard />}>
          <Route path="dashboard" element={<Overview />} />
          <Route index element={<Overview />} />
          <Route path="dashboard/customers" element={<Customers />} />
          <Route
            path="dashboard/customers/:customerId"
            element={<CustomerDetails />}
          />

          <Route
            path="office/my-time-entries"
            element={<HarvestTimeEntries />}
          />
          <Route path="/office/my-assignments" element={<MyAssignments />} />
          <Route path="profile" element={<Profile />} />
          <>
            <Route path="analytics" element={<Analytics />} />
            <Route path="settings" element={<Settings />} />
            <Route
              path="office/harvest-project-management"
              element={<HarvestProjectManagement />}
            />

            <Route
              path="office/quickbooks/aged-receivables"
              element={<AgedReceivables />}
            />
            <Route
              path="office/quickbooks/aged-receivable-detail"
              element={<AgedReceivableDetails />}
            />
            <Route
              path="office/quickbooks/aged-payables"
              element={<AgedPayables />}
            />
            <Route
              path="office/quickbooks/aged-payable-detail"
              element={<AgedPayableDetails />}
            />
          </>
        </Route>
      </Routes>
    );
  }
};

export default AppRoutes;
