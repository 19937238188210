import React from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import { BarChart, PieChart } from "@mui/icons-material";

export default function Analytics() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Analytics Dashboard
      </Typography>

      <Grid container spacing={3}>
        {/* Example Card 1 */}
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Sales Overview
              </Typography>
              <BarChart sx={{ fontSize: 50, color: "primary.main" }} />
              <Typography>
                Monitor sales trends and performance metrics.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Example Card 2 */}
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                User Engagement
              </Typography>
              <Typography>
                Track user activity and behavior over time.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Example Card 3 */}
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Market Share
              </Typography>
              <PieChart sx={{ fontSize: 50, color: "success.main" }} />
              <Typography>
                Analyze market trends and competitor performance.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
