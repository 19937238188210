import React from "react";
import { Box, Typography, Avatar, Grid, Chip, Divider, Stack } from "@mui/material";
import { useGetUser } from "../hooks/useUser";
import GradeIcon from "@mui/icons-material/Grade";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const Profile: React.FC = () => {
  const { data: user, isLoading, error } = useGetUser();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <Typography variant="h6">Loading profile...</Typography>
      </Box>
    );
  }

  if (error || !user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <Typography variant="h6" color="error">
          Failed to load profile.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
          mb: 3,
        }}
      >
        <Avatar
          alt={user.name}
          src="/static/images/avatar/7.jpg"
          sx={{ width: 100, height: 100 }}
        />
        <Typography variant="h5" fontWeight="bold">
          {user.name}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Level: Advanced User
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        Personal Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <EmailIcon color="primary" />
            <Typography variant="body1">{user.email}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PhoneIcon color="primary" />
            <Typography variant="body1">{user.mobile_number || "Not provided"}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        Your Badges
      </Typography>
      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
        <Chip
          icon={<GradeIcon />}
          label="Beginner Badge"
          color="primary"
          sx={{ cursor: "pointer" }}
        />
        <Chip
          icon={<GradeIcon />}
          label="Pro Badge"
          color="secondary"
          sx={{ cursor: "pointer" }}
        />
        <Chip
          icon={<GradeIcon />}
          label="Expert Badge"
          color="success"
          sx={{ cursor: "pointer" }}
        />
      </Box>
    </Box>
  );
};

export default Profile;
