import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  Paper,
  TableCell,
  TableSortLabel,
  TableHead,
  TablePagination,
  Button,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import TimerIcon from '@mui/icons-material/Timer';
import dayjs, { Dayjs } from 'dayjs';
import { fetchTimeEntriesByUserId } from '../utils/customer/queries';
import useDebounce from '../hooks/useDebounce';
import { useGetUser } from '../hooks/useUser';
import { restartTimer, startTimer, stopTimer } from '../hooks/useHarvest';
import { logAnalyticsEvent } from '../utils/functions';

interface TimeEntry {
  id: number;
  project: { id: number; name: string };
  hours: number;
  notes: string | null;
  client: { name: string };
  user: { name: string };
  task: { id: string; name: string };
  billable: boolean;
  spent_date: string;
  is_running: boolean;
  timer_started_at: string | null;
}

const columns = [
  { id: 'projectLink', label: 'Project', sortable: false },
  { id: 'hours', label: 'Hours', sortable: true },
  { id: 'notes', label: 'Notes', sortable: false },
  { id: 'client.name', label: 'Client', sortable: false },
  { id: 'user.name', label: 'User', sortable: false },
  { id: 'task.name', label: 'Task', sortable: false },
  { id: 'billable', label: 'Billable', sortable: true },
  { id: 'spent_date', label: 'Date', sortable: true },
  { id: 'timer', label: 'Timer', sortable: false },
];

const HarvestTimeEntries: React.FC = () => {
  const { data: user } = useGetUser();
  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([]);
  const [timers, setTimers] = useState<{ [id: number]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [billable, setBillable] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [sortField, setSortField] = useState<string>('spent_date');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const timerIntervals = useRef<{ [id: number]: NodeJS.Timeout }>({});

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (user?.email) {
      fetchData();
    }
  }, [user?.email, debouncedSearch, billable, dateRange, sortField, sortOrder, page, pageSize]);

  useEffect(() => {
    return () => {
      Object.values(timerIntervals.current).forEach(clearInterval);
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const queryParams = {
        search: debouncedSearch,
        billable: billable === 'true' ? true : billable === 'false' ? false : undefined,
        startDate: dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : undefined,
        endDate: dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : undefined,
        sort: sortField,
        order: sortOrder.toUpperCase(),
        page: page + 1,
        pageSize,
      };
      const data = await fetchTimeEntriesByUserId(user?.email, queryParams as any);
      setTimeEntries(data.records);
      setTotal(data.total);

      data.records.forEach((entry: TimeEntry) => {
        if (entry.is_running && entry.timer_started_at) {
          startTimerDisplay(entry.id, entry.timer_started_at);
        }
      });
    } catch (error) {
      console.error('Error fetching time entries:', error);
    } finally {
      setLoading(false);
    }
  };

  const startTimerDisplay = (id: number, startTime: string) => {
    clearInterval(timerIntervals.current[id]);
    timerIntervals.current[id] = setInterval(() => {
      const elapsed = new Date().getTime() - new Date(startTime).getTime();
      const hours = Math.floor(elapsed / (1000 * 60 * 60));
      const minutes = Math.floor((elapsed / (1000 * 60)) % 60);
      const seconds = Math.floor((elapsed / 1000) % 60);
      setTimers((prev) => ({
        ...prev,
        [id]: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
      }));
    }, 1000);
  };

  const handleTimerAction = async (action: 'start' | 'stop' | 'restart', entry: TimeEntry) => {
    try {
     if (action === 'stop') {
        await stopTimer(user?.email, entry.id);
        logAnalyticsEvent("stop_timer_harvest",user?.id,{entryId:entry.id})
        clearInterval(timerIntervals.current[entry.id]);
        setTimers((prev) => ({ ...prev, [entry.id]: '00:00:00' }));
      } else if (action === 'restart') {
        const response = await restartTimer(user?.email, entry.id);
        logAnalyticsEvent("restart_timer_harvest",user?.id,{entryId:entry.id})
        startTimerDisplay(entry.id, response.timer_started_at);
      }
      fetchData();
    } catch (error) {
      console.error(`Failed to ${action} timer:`, error);
    }
  };

  const renderCellValue = (row: TimeEntry, field: string) => {
    const fieldParts = field.split('.');
    let value: any = row;

    for (const part of fieldParts) {
      value = value ? value[part] : null;
    }

    switch (field) {
      case 'projectLink':
        return (
          <a href={`https://harvestapp.com/projects/${row.project.id}`} target="_blank" rel="noopener noreferrer">
            {row.project.name}
          </a>
        );
      case 'timer':
        return row.is_running ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <TimerIcon color="action" />
            <Typography>{timers[row.id] || 'Running...'}</Typography>
            <Button size="small" variant="contained" color="secondary" onClick={() => handleTimerAction('stop', row)}>
              Stop
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            <Button size="small" variant="outlined" onClick={() => handleTimerAction('restart', row)}>
              Restart
            </Button>
          </Stack>
        );
        case 'spent_date':
          return dayjs(value).format('DD/MM/YY');
      default:
        return value !== null && value !== undefined ? value : 'N/A';
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <Select
              value={billable || ''}
              onChange={(e) => setBillable(e.target.value || undefined)}
              displayEmpty
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePicker
            value={dateRange[0]}
            onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
            slotProps={{ textField: { fullWidth: true, placeholder: 'Start Date' } }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePicker
            value={dateRange[1]}
            onChange={(newValue) => setDateRange([dateRange[0], newValue])}
            slotProps={{ textField: { fullWidth: true, placeholder: 'End Date' } }}
          />
        </Grid>
      </Grid>
      {loading ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {column.sortable ? (
                      <TableSortLabel
                        active={sortField === column.id}
                        direction={sortField === column.id ? sortOrder : 'asc'}
                        onClick={() => setSortField(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {timeEntries.map((entry) => (
                <TableRow key={entry.id}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{renderCellValue(entry, column.id)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            page={page}
            rowsPerPage={pageSize}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value, 10))}
          />
        </TableContainer>
      )}
    </Box>
  );
};

export default HarvestTimeEntries;
