import { saveAs } from 'file-saver';
import api from './api';

export const exportToCSV = (data:any, filename:any) => {
  const csvRows = [];
  
  // Extract headers from the first object keys, flattening nested objects
  const headers = Object.keys(flattenObject(data[0].dataValues));
  csvRows.push(headers.join(","));
  
  // Map rows of data
  data.forEach((customer:any) => {
    const flattenedData:any = flattenObject(customer.dataValues);
    const values = headers.map((header) => {
      const escaped = ('' + (flattenedData[header] || "")).replace(/"/g, '\\"'); // Escape double quotes
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  });

  // Create a blob and trigger download
  const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
  saveAs(blob, filename);
};

// Helper function to flatten nested objects
function flattenObject(obj:any, parentKey = '', result:any = {}) {
  for (let key in obj) {
    const fullKey = parentKey ? `${parentKey}_${key}` : key;
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      flattenObject(obj[key], fullKey, result);
    } else {
      result[fullKey] = obj[key];
    }
  }
  return result;
}

interface Metadata {
  [key: string]: any; // Allows any key-value pairs
}

interface LogAnalyticsEventResponse {
  success: boolean;
  message?: string;
}

export async function logAnalyticsEvent(
  eventType: string,
  userId: string|undefined,
  metadata: Metadata = {}
): Promise<LogAnalyticsEventResponse> {
  try {
    const response = await api.post<LogAnalyticsEventResponse>('/api/service/analytics/log', {
      eventType,
      userId,
      metadata,
    });
    console.log('Event logged successfully:', response.data);
    return response.data;
  } catch (error: any) {
    console.error('Error logging analytics event:', error.response?.data || error.message);
    throw new Error('Failed to log analytics event');
  }
}