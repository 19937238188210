import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Link as MuiLink,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import {
  Person,
  Mail,
  Phone,
  Cake,
  Home,
  Business,
  AccountBalance,
  Work,
  FamilyRestroom,
  Badge,
  Computer,
  Gavel,
  Place,
  CalendarToday,
  Star,
  AccountCircle,
  ChildCare,
  Contacts,
  PermIdentity,
} from "@mui/icons-material";
import { ICustomer, CustomerType } from "../utils/customer";
import { Link } from "react-router-dom";

const formatDate = (date: string | undefined) =>
  date ? dayjs(date).format("MM/DD/YYYY") : "N/A";

interface SalesforceDetailsProps {
  customer: ICustomer;
  spouseContact?: ICustomer;
  familyContact?: ICustomer;
  primaryContact?: ICustomer;
}

const SalesforceDetails: React.FC<SalesforceDetailsProps> = ({
  customer,
  spouseContact,
  familyContact,
  primaryContact,
}) => {
  const createPhoneLink = (phoneNumber: string | undefined) => {
    return phoneNumber
      ? `rcapp://r/call?number=${phoneNumber}`
      : "";
  };

  const salesforceUrl = `https://cuddyfinancial2024.my.salesforce.com/${customer.salesforce_contact_id}`;
  const teamsUrl = customer.team_id ? `https://teams.microsoft.com/l/team/${customer.team_id}` : null;


  const renderFields = (
    fields: {
      title: string;
      value: React.ReactNode | React.ReactNode[];
      icon: JSX.Element;
    }[]
  ) => (
    <Grid container spacing={2}>
      {fields.map((field, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} sm={7} md={7}>
            <Box display="flex" alignItems="center">

              {React.cloneElement(field.icon, { sx: { color: "text.secondary", mb: 0.5, fontSize: "20px" } })}
              <Typography
                variant="body2"
                sx={{ ml: 1, color: "text.secondary", width: "100%", fontSize: "0.75rem" }} 
              >
                {field.title}
              </Typography>
            </Box>
          </Grid>
          {/* Field Value */}
          <Grid item xs={8} sm={5} md={5}>
            <Box display="flex" flexDirection="column">
              {Array.isArray(field.value) ? (
                field.value.map((item: any, idx: number) => (
                  <Typography
                    key={idx}
                    variant="body1"
                    component="div"
                    sx={{ fontWeight: "bold", mt: 0.5, fontSize: "0.75rem" }} 
                  >
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ fontWeight: "bold", mt: 0.5, fontSize: "0.75rem" }} 
                >
                  {field.value || "N/A"}
                </Typography>
              )}
            </Box>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
  
  // Extracting address fields
  const {
    mailing_address,
    physical_address,
    mailing_address: {
      street: mailingStreet = "",
      city: mailingCity = "",
      state: mailingState = "",
      postalCode: mailingPostalCode = "",
      country: mailingCountry = "",
    } = {},
    physical_address: {
      street: physicalStreet = "",
      city: physicalCity = "",
      state: physicalState = "",
      postalCode: physicalPostalCode = "",
      country: physicalCountry = "",
    } = {},
  } = customer;

  // Helper function to format addresses
  const formatAddress = (address: any) => {
    if (!address) return "N/A";
    const { street = "", city = "", state = "", postalCode = "", country = "" } = address;
    return `${street}, ${city}, ${state} ${postalCode}, ${country}`;
  };

  // Define fields for each customer type
  const personAccountFields = [
    {
      title: "Personal Information",
      fields: [
        {
          title: "Account Name",
          value:
            customer.full_name ||
            `${customer.first_name || ""} ${customer.last_name || ""}`,
          icon: <Person />,
        },
        {
          title: "Spouse",
          value: spouseContact ? (
            <Link to={`/dashboard/customers/${spouseContact.id}`} style={{ textDecoration: "none", color: "blue" }}>
              {spouseContact.full_name ||
                `${spouseContact.first_name || ""} ${spouseContact.last_name || ""}`}
            </Link>
          ) : (
            "N/A"
          ),
          icon: <FamilyRestroom />,
        },
        {
          title: "Greeting",
          value: customer.greeting || "N/A",
          icon: <Mail />,
        },
        {
          title: "Birth Date",
          value: formatDate(customer.birth_date),
          icon: <Cake />,
        },
        { title: "SSN", value: customer.ssn || "N/A", icon: <Badge /> },
        { title: "Gender", value: customer.gender || "N/A", icon: <Person /> },
        {
          title: "Marital Status",
          value: customer.marital_status || "N/A",
          icon: <FamilyRestroom />,
        },
        { title: "Age", value: customer.age?.toString() || "N/A", icon: <Cake /> },
        {
          title: "CFS Client ID",
          value: customer.new_cfs_client_id || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "CFS Client ID (HH)",
          value: familyContact?.new_cfs_client_id || "N/A",
          icon: <AccountBalance />,
        },
      ],
    },
    {
      title: "Contact Information",
      fields: [
        {
          title: "Email",
          value: customer.email ? (
            <MuiLink href={`mailto:${customer.email}`} underline="hover">
              {customer.email}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Mail />,
        },
        {
          title: "Primary Phone Type",
          value: customer.primary_phone_type || "N/A",
          icon: <Phone />,
        },
        {
          title: "Spouse Mobile",
          value: spouseContact?.mobile_number ? (
            <MuiLink
              href={createPhoneLink(spouseContact.mobile_number)}
              underline="hover"
            >
              {spouseContact.mobile_number}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Phone />,
        },
        {
          title: "Mobile",
          value: customer.mobile_number ? (
            <MuiLink
              href={createPhoneLink(customer.mobile_number)}
              underline="hover"
            >
              {customer.mobile_number}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Phone />,
        },
        {
          title: "Home Phone",
          value: customer.home_phone ? (
            <MuiLink
              href={createPhoneLink(customer.home_phone)}
              underline="hover"
            >
              {customer.home_phone}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Phone />,
        },
        {
          title: "Work Phone",
          value: customer.work_phone ? (
            <MuiLink
              href={createPhoneLink(customer.work_phone)}
              underline="hover"
            >
              {customer.work_phone}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Work />,
        },
     
        {
          title: "Primary Phone",
          value: customer.primary_phone ? (
            <MuiLink
              href={createPhoneLink(customer.primary_phone)}
              underline="hover"
            >
              {customer.primary_phone}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Phone />,
        }

      ],
    },
    {
      title: "Addresses",
      fields: [
        {
          title: "Mailing Address",
          value: formatAddress(customer.mailing_address),
          icon: <Home />,
        },
        {
          title: "Physical Address",
          value: formatAddress(customer.physical_address),
          icon: <Home />,
        },
      ],
    },
    {
      title: "Additional Information",
      fields: [

        { title: "Rating", value: customer.rating || "N/A", icon: <Star /> },
        {
          title: "WM Service Level",
          value: customer.wm_service_level || "N/A",
          icon: <Work />,
        },
        { title: "Status", value: customer.status || "N/A", icon: <Badge /> },
        {
          title: "Entity Type",
          value: customer.customer_type || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "Services Provided",
          value: customer.services_provided?.join(", ") || "N/A",
          icon: <Work />,
        },
      ],
    },
    {
      title: "Legal & Financial",
      fields: [
        {
          title: "Driver License",
          value: customer.driver_license
            ? [
                `Number: ${customer.driver_license.number || "N/A"}`,
                `Issue Date: ${formatDate(customer.driver_license.issue_date)}`,
                `Expiration Date: ${formatDate(
                  customer.driver_license.expiration_date
                )}`,
                `State: ${customer.driver_license.state || "N/A"}`,
                `Doc ID: ${customer.driver_license.doc_id || "N/A"}`,
              ]
            : "N/A",
          icon: <Badge />,
        },
        {
          title: "Trusted Contact",
          value: customer.trusted_contact
            ? [
                `Name: ${customer.trusted_contact.name || "N/A"}`,
                `Phone: ${
                  customer.trusted_contact.phone ? (
                    <MuiLink
                      href={createPhoneLink(customer.trusted_contact.phone)}
                      underline="hover"
                    >
                      {customer.trusted_contact.phone}
                    </MuiLink>
                  ) : (
                    "N/A"
                  )
                }`,
                `Email Address: ${
                  customer.trusted_contact.emailAddress ? (
                    <MuiLink
                      href={`mailto:${customer.trusted_contact.emailAddress}`}
                      underline="hover"
                    >
                      {customer.trusted_contact.emailAddress}
                    </MuiLink>
                  ) : (
                    "N/A"
                  )
                }`,
                `Address: ${
                  customer.trusted_contact.address
                    ? formatAddress(customer.trusted_contact.address)
                    : "N/A"
                }`,
              ]
            : "N/A",
          icon: <Contacts />,
        },
        {
          title: "Law Firm",
          value: customer.attorney || "N/A",
          icon: <Gavel />,
        },
      ],
    },
  ];

  const householdFields = [
    {
      title: "Household Information",
      fields: [
        {
          title: "Household Name",
          value:
            customer.full_name ||
            `${customer.first_name || ""} ${customer.last_name || ""}`,
          icon: <Home />,
        },
        {
          title: "Account Record Type",
          value: customer.customer_type || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "Greeting",
          value: customer.greeting
            ? `${customer.greeting}` 
            : `${primaryContact?.first_name || "N/A"} & ${spouseContact?.first_name || "N/A"}`,
          icon: <Mail />,
        },
        {
          title: "Primary Greeting",
          value: `${primaryContact?.gender === 'Male' ? 'Mr.' : 'Ms.'} ${primaryContact?.first_name || customer.first_name || 'N/A'}`,
          icon: <Person />,
        },
        {
          title: "Spouse Greeting",
          value: `${spouseContact?.gender === 'Male' ? 'Mr.' : 'Ms.'} ${spouseContact?.first_name || 'N/A'}`,
          icon: <Person />,
        },
        {
          title: "Formal Greeting",
          value: `${primaryContact?.gender === 'Male' ? 'Mr.' : 'Ms.'} ${primaryContact?.first_name || customer.first_name || 'N/A'} & ${spouseContact?.gender === 'Male' ? 'Mr.' : 'Ms.'} ${spouseContact?.first_name || 'N/A'}`,
          icon: <Person />,
        },
                {
          title: "CFS Client ID",
          value: customer.new_cfs_client_id || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "Account Primary",
          value: primaryContact ? (
            <Link to={`/dashboard/customers/${primaryContact.id}`} style={{ textDecoration: "none", color: "blue" }}>
              {primaryContact.full_name ||
                `${primaryContact.first_name || ""} ${primaryContact.last_name || ""}`}
            </Link>
          ) : (
            "N/A"
          ),
          icon: <PermIdentity />,
        },
        {
          title: "Account Spouse",
          value: spouseContact ? (
            <Link to={`/dashboard/customers/${spouseContact.id}`} style={{ textDecoration: "none", color: "blue" }}>
              {spouseContact.full_name ||
                `${spouseContact.first_name || ""} ${spouseContact.last_name || ""}`}
            </Link>
          ) : (
            "N/A"
          ),
          icon: <FamilyRestroom />,
        },
        {
          title: "Primary Member SS#",
          value: primaryContact?.ssn || "N/A",
          icon: <Badge />,
        },
        {
          title: "Spouse SS#",
          value: spouseContact?.ssn || "N/A",
          icon: <Badge />,
        },
       
        {
          title: "Primary Member DOB",
          value: formatDate(primaryContact?.birth_date),
          icon: <Cake />,
        },
        { title: "Primary Member Age", value: primaryContact?.age?.toString() || "N/A", icon: <Cake /> },
        {
          title: "Spouse DOB",
          value: formatDate(spouseContact?.birth_date),
          icon: <Cake />,
        },
        { title: "Spouse Age", value: spouseContact?.age?.toString() || "N/A", icon: <Cake /> },
        {
          title: "Primary Phone",
          value: primaryContact?.primary_phone ? (
            <MuiLink
              href={createPhoneLink(primaryContact.primary_phone)}
              underline="hover"
            >
              {primaryContact.primary_phone}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Phone />,
        },
       
        {
          title: "Email Address",
          value: primaryContact?.email ? (
            <MuiLink href={`mailto:${primaryContact.email}`} underline="hover">
              {primaryContact.email}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Mail />,
        },
        {
          title: "Marital Status",
          value: customer.marital_status || "N/A",
          icon: <FamilyRestroom />,
        },
        {
          title: "Primary Member Employment Status",
          value: customer?.Primary_Member_Employment || "N/A",
          icon: <Work />,
        },
        {
          title: "Spouse Employment Status",
          value: customer?.Spouse_Employment || "N/A",
          icon: <Work />,
        },
        {
          title: "Service Level",
          value: customer.wm_service_level || "N/A",
          icon: <Work />,
        },
        {
          title: "Number of Children",
          value: customer.dependent_children
            ? customer.dependent_children.length.toString()
            : "0",
          icon: <ChildCare />,
        },
        {
          title: "Status",
          value: customer.status || "N/A",
          icon: <Badge />,
        },
        {
          title: "Services Provided",
          value: customer.services_provided?.join(", ") || "N/A",
          icon: <Work />,
        },
      ],
    },
    {
      title: "Addresses",
      fields: [
        {
          title: "Billing Address",
          value: formatAddress(customer.mailing_address),
          icon: <Home />,
        },
        {
          title: "Shipping Address",
          value: formatAddress(customer.physical_address),
          icon: <Home />,
        },
      ],
    },
    {
      title: "Relationships",
      fields: [
        {
          title: "Primary Account",
          value: primaryContact ? (
            <Link to={`/dashboard/customers/${primaryContact.id}`} style={{ textDecoration: "none", color: "blue" }}>
              {primaryContact.full_name ||
                `${primaryContact.first_name || ""} ${primaryContact.last_name || ""}`}
            </Link>
          ) : (
            "N/A"
          ),
          icon: <Person />,
        },
        {
          title: "Spouse Account",
          value: spouseContact ? (
            <Link to={`/dashboard/customers/${spouseContact.id}`} style={{ textDecoration: "none", color: "blue" }}>
              {spouseContact.full_name ||
                `${spouseContact.first_name || ""} ${spouseContact.last_name || ""}`}
            </Link>
          ) : (
            "N/A"
          ),
          icon: <FamilyRestroom />,
        },
        ...(customer.dependent_children || []).map((child) => ({
          title: "Dependent Child",
          value: (
            <Link to={`/dashboard/customers/${child.id}`} style={{ textDecoration: "none", color: "blue" }}>
              {child.full_name ||
                `${child.first_name || ""} ${child.last_name || ""}`}
              {child.birth_date ? `, DOB: ${formatDate(child.birth_date)}` : ""}
              {child.ssn ? `, SS#: ${child.ssn}` : ""}
            </Link>
          ),
          icon: <ChildCare />,
        })),
      ],
    },
    {
      title: "Legal & Financial",
      fields: [
        {
          title: "Bank Asset",
          value: customer.bank_account_info
            ? [
                `Bank Name: ${customer.bank_account_info.bank_name || "N/A"}`,
                `Routing Number: ${customer.bank_account_info.routing || "N/A"}`,
                `Account Number: ${customer.bank_account_info.account || "N/A"}`,
                `Checking: ${customer.bank_account_info.checking ? "Yes" : "No"}`,
                `Savings: ${customer.bank_account_info.savings ? "Yes" : "No"}`,
              ]
            : "N/A",
          icon: <AccountBalance />,
        },
      ],
    },
  ];

  const businessClientFields = [
    {
      title: "Business Information",
      fields: [
        {
          title: "Business Name",
          value: customer.full_name || "N/A",
          icon: <Business />,
        },
        {
          title: "DBA",
          value: customer.dba || "N/A",
          icon: <Business />,
        },
        {
          title: "Greeting",
          value: customer.greeting || "N/A",
          icon: <Mail />,
        },
        {
          title: "Primary Member",
          value: primaryContact ? (
            <Link to={`/dashboard/customers/${primaryContact.id}`} style={{ textDecoration: "none", color: "blue" }}>
              {primaryContact.full_name ||
                `${primaryContact.first_name || ""} ${primaryContact.last_name || ""}`}
            </Link>
          ) : (
            "N/A"
          ),
          icon: <Person />,
        },
        {
          title: "Business Phone",
          value: customer.mobile_number ? (
            <MuiLink
              href={createPhoneLink(customer.mobile_number)}
              underline="hover"
            >
              {customer.mobile_number}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Phone />,
        },
        {
          title: "Campaigns",
          value: customer.campaigns || "N/A",
          icon: <Work />,
        },
        {
          title: "Services Provided 1",
          value: customer.services_provided_1 || "N/A",
          icon: <Work />,
        },
        {
          title: "Services Provided 2",
          value: customer.services_provided_2 || "N/A",
          icon: <Work />,
        },
        {
          title: "EIN",
          value: customer.ein || "N/A",
          icon: <Badge />,
        },
        {
          title: "EIN Not Needed",
          value: customer.ein_not_needed ? "True" : "False",
          icon: <Badge />,
        },
        {
          title: "Account Record Type",
          value: customer.customer_type || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "CFS Client ID",
          value: customer.cfs_client_id || "N/A",
          icon: <AccountBalance />,
        },
      ],
    },
    {
      title: "Business Related Information",
      fields: [
        {
          title: "Business Start Date",
          value: formatDate(customer.business_start_date),
          icon: <CalendarToday />,
        },
        {
          title: "S Corp Effective Date",
          value: formatDate(customer.s_corp_effective_date),
          icon: <CalendarToday />,
        },
        {
          title: "Business Classification",
          value: customer.business_classification || "N/A",
          icon: <Work />,
        },
        {
          title: "Tax Return Due Date Month",
          value: customer.tr_due_date_month || "N/A",
          icon: <CalendarToday />,
        },
        {
          title: "Fiscal Year",
          value: customer.fiscal_year || "N/A",
          icon: <CalendarToday />,
        },
        {
          title: "Additional State",
          value: customer.additional_state || "N/A",
          icon: <Place />,
        },
        {
          title: "Tax Return",
          value: customer.tax_return || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "Accounting",
          value: customer.accounting || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "Software",
          value: customer.software || "N/A",
          icon: <Computer />,
        },
      ],
    },
    {
      title: "Legal Entity Type",
      fields: [
        {
          title: "NAICS#",
          value: customer.naics || "N/A",
          icon: <Business />,
        },
        {
          title: "Industry",
          value: customer.industry || "N/A",
          icon: <Business />,
        },
        {
          title: "Law Firm",
          value: customer.law_firm || "N/A",
          icon: <Gavel />,
        },
        {
          title: "Attorney",
          value: customer.attorney || "N/A",
          icon: <Gavel />,
        },
      ],
    },
    {
      title: "Addresses",
      fields: [
        {
          title: "Billing Address",
          value: formatAddress(customer.mailing_address),
          icon: <Home />,
        },
        {
          title: "Shipping Address",
          value: formatAddress(customer.physical_address),
          icon: <Home />,
        },
      ],
    },
  ];

  const institutionFields = [
    {
      title: "Institution Information",
      fields: [
        {
          title: "Institution Name",
          value: customer.full_name || "N/A",
          icon: <Business />,
        },
        {
          title: "Website",
          value: customer.website ? (
            <MuiLink href={customer.website} target="_blank" underline="hover">
              {customer.website}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Mail />,
        },
        {
          title: "Email Address",
          value: customer.email ? (
            <MuiLink href={`mailto:${customer.email}`} underline="hover">
              {customer.email}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Mail />,
        },
        {
          title: "Fax Number",
          value: customer.fax_number || "N/A",
          icon: <Phone />,
        },
        {
          title: "Primary Phone Number",
          value: customer.primary_phone ? (
            <MuiLink
              href={createPhoneLink(customer.primary_phone)}
              underline="hover"
            >
              {customer.primary_phone}
            </MuiLink>
          ) : (
            "N/A"
          ),
          icon: <Phone />,
        },
        {
          title: "Contact (Primary Member)",
          value: primaryContact ? (
            <Link to={`/dashboard/customers/${primaryContact.id}`} style={{ textDecoration: "none", color: "blue" }}>
              {primaryContact.full_name ||
                `${primaryContact.first_name || ""} ${primaryContact.last_name || ""}`}
            </Link>
          ) : (
            "N/A"
          ),
          icon: <Person />,
        },
        {
          title: "EIN",
          value: customer.ein || "N/A",
          icon: <Badge />,
        },
        {
          title: "EIN Not Needed",
          value: customer.ein_not_needed ? "True" : "False",
          icon: <Badge />,
        },
      ],
    },
    {
      title: "Relationships",
      fields: [
        { title: "Vendor", value: customer.vendor || "N/A", icon: <Work /> },
        {
          title: "Investment Co.",
          value: customer.investment_company || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "Professional Contact",
          value: customer.professional_contact || "N/A",
          icon: <Person />,
        },
        {
          title: "Annuity Co.",
          value: customer.annuity_company || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "Insurance Co.",
          value: customer.insurance_company || "N/A",
          icon: <AccountBalance />,
        },
        {
          title: "Insurance Broker",
          value: customer.insurance_broker || "N/A",
          icon: <Work />,
        },
        {
          title: "Software Provider",
          value: customer.software_provider || "N/A",
          icon: <Computer />,
        },
        {
          title: "Newsletter Service",
          value: customer.newsletter_service || "N/A",
          icon: <Mail />,
        },
        {
          title: "App Subscription",
          value: customer.app_subscription || "N/A",
          icon: <Computer />,
        },
        {
          title: "Consultant",
          value: customer.consultant || "N/A",
          icon: <Person />,
        },
      ],
    },
    {
      title: "Payment Instructions",
      fields: [
        {
          title: "Steps to Complete",
          value: [
            "Payment Options:",
            "1. ACH",
            "2. Wire Transfer",
            "3. Check",
            "4. Credit Card with Fee",
            "5. Credit Card without Fee",
          ],
          icon: <AccountBalance />,
        },
      ],
    },
    {
      title: "Addresses",
      fields: [
        {
          title: "Mailing Address",
          value: formatAddress(customer.mailing_address),
          icon: <Home />,
        },
        {
          title: "Physical Address",
          value: formatAddress(customer.physical_address),
          icon: <Home />,
        },
      ],
    },
  ];

  const getFields = () => {
    switch (customer.customer_type) {
      case CustomerType.PersonAccount:
        return personAccountFields;
      case CustomerType.BusinessClients:
        return businessClientFields;
      case CustomerType.Household:
        return householdFields;
      case CustomerType.Institution:
        return institutionFields;
      default:
        return [];
    }
  };

  return (
    <>
       <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', // Added to space between name and icons
          p: 1, // Padding of 2 * 8px = 16px
          borderBottom: '1px solid #e0e0e0', // Light gray border
        }}
      >
        <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
          {customer.full_name ||
            `${customer.first_name} ${customer.last_name}` ||
            'Customer Details'}
        </Typography>
        {/* Icons */}
        <Box sx={{ display: 'flex', gap: 1 }}> {/* Gap of 1 * 8px = 8px */}
          <IconButton
            component="a"
            href={salesforceUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Open in Salesforce"
          >
            <img src={"/assets/Logos/salesforce-icon.png"} alt="Salesforce" width={16} height={16} />
          </IconButton>
          {teamsUrl && (
            <IconButton
              component="a"
              href={teamsUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open in Teams"
            >
              <img src={"/assets/Logos/Microsoft_Teams.png"} alt="Teams" width={16} height={16} />
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
      {getFields().map((section, idx) => (
        <Box key={idx} sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom  sx={{color: "text.secondary" }}>
            {section.title}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {renderFields(section.fields)}
        </Box>
      ))}
    </>
  );
};

export default SalesforceDetails;
