import React, { useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  IconButton,
  Grid,
  CardHeader,
  Avatar,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Skeleton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Import your hooks and types
import { ICustomer } from "../utils/customer";
import {
  useTrelloFetchCards,
  useTrelloCreateCard,
  useTrelloFetchBoardsAndLists,
  CreateCardInput,
} from "../hooks/useTrello";
import { TrelloCard } from "../types/trello";
import { UseMutationResult } from "@tanstack/react-query";
import { ApiError } from "../types/general";
import { useGetUser } from "../hooks/useUser";

interface TrelloSectionProps {
  customer: ICustomer;
}

function extractLabelUrlPairs(text: string): { label: string; url: string }[] {
  const regex = /(.*?):\s*(https?:\/\/[^\s]+)/g;
  const matches = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    matches.push({ label: match[1].trim(), url: match[2] });
  }
  return matches;
}

const TrelloSection: React.FC<TrelloSectionProps> = ({ customer }) => {
  const textValue = customer?.new_cfs_client_id || "";

  // Fetch Trello data
  const {
    data: trelloData,
    error: trelloError,
    isLoading: isTrelloLoading,
    refetch: refetchTrelloCards,
  } = useTrelloFetchCards(textValue);

  // State variables for modal and form
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState("");
  const [selectedListId, setSelectedListId] = useState("");
  const [cardName, setCardName] = useState(
    customer.full_name || customer.first_name + " " + customer.last_name
  );
  const [cardDescription, setCardDescription] = useState("");
  const { data: user } = useGetUser();
  // State variables for stepper
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Select Board", "Select List", "Enter Details"];

  // Fetch boards and lists
  const {
    data: boardsData,
    error: boardsError,
    isLoading: isBoardsLoading,
  } = useTrelloFetchBoardsAndLists();

  // Mutation for creating a card
  const createCardMutation = useTrelloCreateCard({
    onSuccess: () => {
      // Close the modal and reset form fields
      handleCloseModal();
      // Refresh the Trello cards
      refetchTrelloCards();
      // Show success message
      setSnackbarMessage("Card created successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    },
    onError: (error: any) => {
      if (
        error?.response?.data?.message === "Invalid custom field item value." &&
        error?.response?.data?.error === "ERROR"
      ) {
        handleCloseModal();
        refetchTrelloCards();
        setSnackbarMessage("Card created successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        console.error("Error creating card:", error);
        // Show error message for other errors
        setSnackbarMessage("Error creating card.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    },
  });

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [dueDate, setDueDate] = useState<string>("");
  const [difficulty, setDifficulty] = useState<string>(
    "652abb43badbbb9613f989c7"
  );

  const difficulties = [
    { id: "652abb43badbbb9613f989c7", label: "Simple" },
    { id: "652abb43badbbb9613f989c8", label: "Medium" },
    { id: "652abb43badbbb9613f989c9", label: "Complex" },
  ];

  // Handle modal open/close
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedBoardId("");
    setSelectedListId("");
    setCardName("");
    setCardDescription("");
    setActiveStep(0);
  };

  // Handle form submission
  const handleCreateCard = () => {
    if (selectedListId && cardName) {
      createCardMutation.mutate({
        userId: user?.id,
        cardData: {
          listId: selectedListId,
          name: cardName,
          description: cardDescription,
          customFields: {
            "66df36bda94cf3097d12780b": {
              text: customer.new_cfs_client_id || customer.cfs_client_id || "",
            }, // CFS ID
            "66b2438a23687d17705a61e4": {
              text: customer.new_cfs_client_id || customer.cfs_client_id || "",
            }, // CSF Household ID
            "66b242c177a8b83a765d7bfe": {
              text: customer.new_cfs_client_id || customer.cfs_client_id || "",
            }, // CFS Client ID
            "66df36bda94cf3097d127810": { date: dueDate }, // DUE DATE
            "652afc3fd32891a33386e132": {
              number: Number(customer.harvest_client_id),
            }, // Harvest Client ID

          },
        },
      });
    }
  };

  // Handle stepper navigation
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Get lists for the selected board
  const selectedBoard = boardsData?.find(
    (board: any) => board.id === selectedBoardId
  );
  const lists = selectedBoard?.lists || [];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      {/* Header with "+" button */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">Trello</Typography>
        <IconButton color="primary" onClick={handleOpenModal}>
          <AddIcon />
        </IconButton>
      </Box>

      {/* Trello Cards */}
      {isTrelloLoading ? (
        <Grid container spacing={2}>
          {Array.from(new Array(6)).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton variant="rectangular" height={200} />
            </Grid>
          ))}
        </Grid>
      ) : trelloError ? (
        <Typography color="error">Error loading Trello data.</Typography>
      ) : trelloData && trelloData.length > 0 ? (
        <Grid container spacing={2}>
          {trelloData.map((card: TrelloCard) => {
            const labelUrlPairs = card.desc
              ? extractLabelUrlPairs(card.desc)
              : [];
            const descriptionWithoutUrls = card.desc
              ? card.desc.replace(/(.*?):\s*(https?:\/\/[^\s]+)/g, "").trim()
              : "";

            return (
              <Grid item xs={12} sm={6} md={4} key={card.id}>
                <Card
                  sx={{
                    "&:hover": {
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar aria-label="board">
                        {card.boardName ? card.boardName.charAt(0) : "B"}
                      </Avatar>
                    }
                    action={
                      <IconButton
                        aria-label="open card"
                        href={card.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    }
                    title={card.name}
                    subheader={`Board: ${card.boardName}`}
                  />
                  <CardContent sx={{ paddingBottom: "16px" }}>
                    {descriptionWithoutUrls && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          paddingBottom: "12px",
                          paddingTop: "8px",
                          wordBreak: "break-word", // Added to prevent overflow
                        }}
                      >
                        {descriptionWithoutUrls}
                      </Typography>
                    )}
                    {labelUrlPairs.length > 0 && (
                      <Box mt={2}>
                        <Typography
                          variant="subtitle2"
                          sx={{ paddingBottom: "8px" }}
                        >
                          Links:
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "8px",
                            mt: 1,
                          }}
                        >
                          {labelUrlPairs.map(({ label, url }, index) => (
                            <Button
                              key={index}
                              variant="outlined"
                              color="primary"
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{
                                padding: "10px 14px",
                                minWidth: "120px",
                                maxWidth: "100%",
                                textAlign: "left",
                                whiteSpace: "normal",
                                minHeight: "80px",
                              }}
                            >
                              {label}
                            </Button>
                          ))}
                        </Box>
                      </Box>
                    )}
                    <Divider sx={{ my: 2 }} />
                    <Box display="flex" alignItems="center" mb={1}>
                      <EventIcon sx={{ mr: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                        <strong>Last Activity:</strong>{" "}
                        {new Date(card.dateLastActivity).toLocaleString()}
                      </Typography>
                    </Box>
                    {card.due && (
                      <Box display="flex" alignItems="center" mb={1}>
                        <AccessTimeIcon sx={{ mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          <strong>Due Date:</strong>{" "}
                          {new Date(card.due).toLocaleString()}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Typography>No Trello data available.</Typography>
      )}

      {/* Create Card Dialog */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create a New Trello Card</DialogTitle>
        <DialogContent>
          {isBoardsLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : boardsError ? (
            <Typography color="error">Error loading Trello boards.</Typography>
          ) : (
            <>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box mt={2}>
                {activeStep === 0 && (
                  <TextField
                    select
                    label="Board"
                    value={selectedBoardId}
                    onChange={(e) => {
                      setSelectedBoardId(e.target.value);
                      setSelectedListId(""); // Reset the list selection
                    }}
                    fullWidth
                    margin="normal"
                    variant="filled"
                  >
                    {boardsData?.map((board: any) => (
                      <MenuItem key={board.id} value={board.id}>
                        {board.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {activeStep === 1 && (
                  <TextField
                    select
                    label="List"
                    value={selectedListId}
                    onChange={(e) => setSelectedListId(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                  >
                    {lists.map((list: any) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {activeStep === 2 && (
                  <>
                    <TextField
                      variant="filled"
                      label="Project Name"
                      value={cardName}
                      onChange={(e) => setCardName(e.target.value)}
                      fullWidth
                      margin="normal"
                      required
                    />
                    <TextField
                      variant="filled"
                      label="Description"
                      value={cardDescription}
                      onChange={(e) => setCardDescription(e.target.value)}
                      fullWidth
                      multiline
                      rows={4}
                      margin="normal"
                      InputProps={{
                        style: {
                          wordBreak: "break-word", // Added to prevent overflow
                        },
                      }}
                    />

                    <TextField
                      variant="filled"
                      label="Due Date"
                      type="date"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </>
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
          {activeStep < steps.length - 1 && (
            <Button
              onClick={handleNext}
              variant="contained"
              color="primary"
              disabled={
                (activeStep === 0 && !selectedBoardId) ||
                (activeStep === 1 && !selectedListId)
              }
            >
              Next
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              onClick={handleCreateCard}
              variant="contained"
              color="primary"
              disabled={!cardName || createCardMutation.status === "pending"}
            >
              {createCardMutation.status === "pending" ? (
                <CircularProgress size={24} />
              ) : (
                "Create Card"
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TrelloSection;
