import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Grid,
  CardActions,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchTasksAssignedToProject,
  fetchUserAssignments,
  startTimer,
  stopTimer,
} from "../../../hooks/useHarvest";
import { useGetUser } from "../../../hooks/useUser";
import { logAnalyticsEvent } from "../../../utils/functions";

const MyAssignments: React.FC = () => {
  const { data: user } = useGetUser();

  // Fetch assignments
  const { data: assignments, error, isLoading, refetch } = useQuery({
    queryKey: ["userAssignments", user?.id],
    queryFn: () => fetchUserAssignments(user?.id),
    enabled: Boolean(user?.id),
  });

  // State to hold tasks by project
  const [tasksByProject, setTasksByProject] = useState<{ [projectId: number]: any[] }>({});
  const [selectedTaskIds, setSelectedTaskIds] = useState<{ [projectId: number]: number }>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchTasksForAssignments = async () => {
      if (assignments && user?.id) {
        const tasksPromises = assignments.map(async (assignment: any) => {
          const tasks = await fetchTasksAssignedToProject(user.id, assignment.project.id);
          return { projectId: assignment.project.id, tasks };
        });
        const tasksResults = await Promise.all(tasksPromises);
        const tasksMap = tasksResults.reduce((acc: any, { projectId, tasks }) => {
          acc[projectId] = tasks;
          return acc;
        }, {});
        setTasksByProject(tasksMap);
      }
    };
    fetchTasksForAssignments();
  }, [assignments, user?.id]);

  // Start timer mutation
  const startTimerMutation = useMutation({
    mutationFn: ({ projectId, taskId }: { projectId: number; taskId: number }) =>
      startTimer(user?.email, projectId, taskId),
    onSuccess: () => {
    // logAnalyticsEvent("create_time_entry_harvest",user?.id,{projectId:projectId,taskId})
      refetch();
      setSnackbarOpen(true);
    },
    onError: () => {
      alert("Failed to start the timer. Please try again.");
    },
  });

  // Stop timer mutation
  const stopTimerMutation = useMutation({
    mutationFn: (timerId: number) => stopTimer(user?.id, timerId),
    onSuccess: (_, { projectId }: any) => {

      refetch();
    },
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">Error loading assignments.</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        My Assignments
      </Typography>

      <Grid container spacing={4}>
        {(assignments || []).map((assignment: any) => (
          <Grid item xs={12} sm={6} md={4} key={assignment.project.id}>
            <Card
              sx={{
                "&:hover": {
                  boxShadow: 6,
                },
                position: "relative",
                p: 2,
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {assignment.project.name}
                </Typography>
                {!assignment.is_timer_running && (
                  <Select
                    value={selectedTaskIds[assignment.project.id] || ""}
                    onChange={(e) =>
                      setSelectedTaskIds({
                        ...selectedTaskIds,
                        [assignment.project.id]: Number(e.target.value),
                      })
                    }
                    displayEmpty
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                  >
                    <MenuItem value="" disabled>
                      Select Task
                    </MenuItem>
                    {(tasksByProject[assignment.project.id] || []).map((taskAssignment) => (
                      <MenuItem key={taskAssignment.task.id} value={taskAssignment.task.id}>
                        {taskAssignment.task.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </CardContent>
              <CardActions sx={{ justifyContent: "space-between" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  href={`https://cuddyfinancialservices.harvestapp.com/projects/${assignment.project.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<OpenInNewIcon />}
                >
                  Open in Harvest
                </Button>
                {assignment.is_timer_running ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() =>
                      stopTimerMutation.mutate({
                        timerId: assignment.timer_id,
                        projectId: assignment.project.id,
                      } as any)
                    }
                  >
                    Stop Timer
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      const taskId = selectedTaskIds[assignment.project.id];
                      if (!taskId) {
                        alert("Please select a task before starting the timer.");
                        return;
                      }
                      startTimerMutation.mutate({ projectId: assignment.project.id, taskId });
                    }}
                  >
                    Start Timer
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          Timer started successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MyAssignments;