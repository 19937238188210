import React from "react";
import { Box } from "@mui/material";
import { ICustomer } from "../utils/customer";
import TrelloSection from "./TrelloSection";
import HarvestSection from "./HarvestSection";

interface ProjectTaskManagementTabProps {
  customer: ICustomer;
}

const ProjectTaskManagementTab: React.FC<ProjectTaskManagementTabProps> = ({
  customer,
}) => {
  return (
    <Box>
      <TrelloSection customer={customer} />
      <HarvestSection customer={customer} />
    </Box>
  );
};

export default ProjectTaskManagementTab;
