import api from '../api';

import { CustomerType, GetCustomerDetailInput, GetCustomersInput } from './dto';

export const getCustomersQuery = (props: GetCustomersInput) => ({
  queryKey: ['customers', props],
  queryFn: async () => (await api.get('/api/customer', { params: props })).data,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
});

export const getCustomersCountQuery = (
  groupId?: string,
  type?: keyof typeof CustomerType,
  parent?: keyof typeof CustomerType,
  additionalParams?: Record<string, any>,
) => ({
  queryKey: ['customers-report', type, parent, additionalParams].filter(Boolean),
  queryFn: async () =>
    (
      await api.get('/api/customer/report', {
        params: { group_id: groupId, parent, type, ...additionalParams },
      })
    ).data,
});

export const getCustomerDetailQuery = (props: GetCustomerDetailInput) => ({
  queryKey: ['customers', props],
  queryFn: async () =>
    (await api.get('/api/customer/detail', { params: props })).data,
});


export const getCustomerHarvestAndQBIdsQuery = (customerSalesforceId: string) => ({
  queryKey: ['customers-harvest-qb-ids', customerSalesforceId],
  queryFn: async () =>
    (await api.get('/api/customer/harvest-qb-ids', { params: { customerSalesforceId } })).data,
});


export const fetchTimeEntriesByUserId = async (userId: string | undefined, queryParams: { search: string; projectId: string | undefined; billable: string | undefined; startDate: any; endDate: any; sort: string; order: "ASC" | "DESC"; page: number; pageSize: number; }) => {
  const response = await api.get(`/api/service/harvest/time-entries/user/${userId}`, { params: queryParams });
  return response.data;
};