import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Chip,
  Link as MuiLink,
  TextField,
  Button,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
  Popover,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridFilterModel,
  GridFilterItem,
} from "@mui/x-data-grid";
import useDebounce from "../hooks/useDebounce";
import { useGetCustomers } from "../utils/customer";
import * as XLSX from "xlsx";
import api from "../utils/api";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import debounce from "lodash.debounce";
import { useGetUser } from "../hooks/useUser";

const CustomToolbar = React.memo(
  ({
    searchValue,
    onSearchChange,
    onExportAll,
    onStatusChange,
    selectedStatuses,
  }: {
    searchValue: string;
    onSearchChange: (value: string) => void;
    onExportAll: () => void;
    onStatusChange: (statuses: string[]) => void;
    selectedStatuses: string[];
    
  }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState(searchValue);
    const { data: user } = useGetUser();

    useEffect(() => {
      const handler = setTimeout(() => {
        onSearchChange(inputValue);
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 500);
    
      return () => {
        clearTimeout(handler);
      };
    }, [inputValue, onSearchChange]);

    const handleInputChange = (e: any) => {
      setInputValue(e.target.value);
    };

    const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
      setAnchorEl(null);
    };

    const handleStatusToggle = (status: string) => {
      const updatedStatuses = selectedStatuses.includes(status)
        ? selectedStatuses.filter((item) => item !== status)
        : [...selectedStatuses, status];
      onStatusChange(updatedStatuses);
    };
    const open = Boolean(anchorEl);
    const id = open ? "status-popover" : undefined;

    return (
      <GridToolbarContainer
        sx={{
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            inputRef={searchInputRef}
            label="Search clients"
            variant="outlined"
            size="small"
            placeholder="Type to search..."
            value={inputValue}
            onChange={handleInputChange}
            sx={{
              minWidth: "200px",
            }}
          />
        </Box>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="text"
            onClick={handleStatusClick}
            startIcon={<CheckCircleIcon />}
          >
            Status
          </Button>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          {user?.is_admin && (
            <Button
              variant="text"
              startIcon={<DownloadIcon />}
              onClick={onExportAll}
            >
              Export
            </Button>
          )}
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box sx={{ padding: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedStatuses.includes("Active")}
                  onChange={() => handleStatusToggle("Active")}
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedStatuses.includes("Inactive")}
                  onChange={() => handleStatusToggle("Inactive")}
                />
              }
              label="Inactive"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedStatuses.includes("Client Relationship")}
                  onChange={() => handleStatusToggle("Client Relationship")}
                />
              }
              label="Client Relationship"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedStatuses.includes("Lost")}
                  onChange={() => handleStatusToggle("Lost")}
                />
              }
              label="Lost"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedStatuses.includes("Deceased")}
                  onChange={() => handleStatusToggle("Deceased")}
                />
              }
              label="Deceased"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedStatuses.includes("Prospect")}
                  onChange={() => handleStatusToggle("Prospect")}
                />
              }
              label="Prospect"
            />
          </Box>
        </Popover>
      </GridToolbarContainer>
    );
  }
);

function Customers() {
  
  const [searchParams] = useSearchParams();
  const customerType =
    (searchParams.get("type") as
      | "Business"
      | "BusinessClients"
      | "EstateTrustAccounts"
      | "Household"
      | "Individual"
      | "Institution"
      | "PersonAccount"
      | "PolicyHolder"
      | "Master") || "PersonAccount";

  const [localSearch, setLocalSearch] = useState<string>(""); // Controls the input field
  const debouncedSearchQuery = useDebounce(localSearch, 500); // Use debounced query for data fetching
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
    "Active",
  ]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "", sort: "asc" },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 20,
    page: 0,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const columns: GridColDef[] = useMemo(
    () => getColumnsForCustomerType(customerType),
    [customerType]
  );

  const operatorMapping: Record<string, string> = {
    contains: "iLike", // "contains" => case-insensitive match
    doesNotContain: "not", // "doesNotContain" => negation operator
    equals: "eq", // "equals" => equality operator
    doesNotEqual: "ne", // "doesNotEqual" => not equal operator
    startsWith: "startsWith", // "startsWith" => match at beginning
    endsWith: "endsWith", // "endsWith" => match at end
    isEmpty: "eq", // "isEmpty" => equivalent to equality check for empty values
    isNotEmpty: "ne", // "isNotEmpty" => not equal to empty
    isAnyOf: "in", // "isAnyOf" => matches any value in a list
  };

  const { data: customersData, isLoading } = useGetCustomers({
    customer_type: customerType,
    pagination: {
      current: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    },
    filters: {
      ...(debouncedSearchQuery ? { search: debouncedSearchQuery } : {}),
      ...(selectedStatuses.length > 0 ? { status: selectedStatuses } : {}),
    },
    additionalFilters: filterModel.items.reduce(
      (acc: Record<string, any>, filterItem) => {
        const { field: columnField, operator, value } = filterItem;
        if (value != null && columnField && operator) {
          const mappedOperator = operatorMapping[operator] || operator;
          acc[columnField] = { operator: mappedOperator, value };
        }
        return acc;
      },
      {}
    ),
    sorting: {
      sortField: sortModel[0]?.field,
      sortDirection: sortModel[0]?.sort,
    },
  });
  console.log(customersData,"customersData")

  const handleExportAll = async () => {
    try {
      // Define the typing for the additionalFilters object
      type AdditionalFiltersType = {
        [key: string]: {
          operator: string;
          value: any;
        };
      };

      const allDataResponse = await api.post(
        "/api/customer/all-filtered-data",
        {
          customer_type: customerType,
          filters: {
            search: debouncedSearchQuery || undefined,
            status: selectedStatuses.length > 0 ? selectedStatuses : undefined,
          },
          additionalFilters: filterModel.items.reduce(
            (acc: Record<string, any>, filterItem) => {
              const { field: columnField, operator, value } = filterItem;
              if (value != null && columnField && operator) {
                const mappedOperator = operatorMapping[operator] || operator;
                acc[columnField] = { operator: mappedOperator, value };
              }
              return acc;
            },
            {}
          ),
          sorting: {
            sortField: sortModel[0]?.field,
            sortDirection: sortModel[0]?.sort,
          },
        },
        { responseType: "blob" }
      );
      const blob = new Blob([allDataResponse.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "filtered_customers_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up after download

      // Revoke the object URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to export all data:", error);
    }
  };

  const handleStatusChange = (statuses: string[]) => {
    setSelectedStatuses(statuses);
  };

  const handlePaginationChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
    console.log(newFilterModel);
    setFilterModel(newFilterModel);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  const handleSearchChange = (value: string) => {
    setLocalSearch(value);
  };

  useEffect(() => {
    console.log("Updated search query:", localSearch);
  }, [localSearch]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "auto",
      }}
    >
      <DataGrid
        autoHeight
        checkboxSelection
        disableColumnMenu
        rows={customersData?.data || []}
        columns={columns}
        paginationMode="server"
        filterMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[20, 50, 100]}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
        rowCount={customersData?.pagination?.total || 0}
        loading={isLoading}
        slots={{
          toolbar: () => (
            <CustomToolbar
              searchValue={localSearch}
              onSearchChange={setLocalSearch}
              onExportAll={handleExportAll}
              onStatusChange={handleStatusChange}
              selectedStatuses={selectedStatuses}
            />
          ),
        }}
        sx={{
          "& .MuiDataGrid-cell": {
            fontSize: "0.75rem",
          },
          "& .MuiDataGrid-columnHeaders": {
            color: "gray",
            fontSize: "0.75rem",
          },
        }}
      />
    </Box>
  );
}

// Helper function for columns
const getColumnsForCustomerType = (customerType: string): GridColDef[] => {
  const createPhoneLink = (phoneNumber: string | undefined) => {
    return phoneNumber
    
      ? `rcapp://r/call?number=${phoneNumber}`
      : "";
  };

  const renderStatus = (status: string) => {
    const colors: {
      [key: string]:
        | "success"
        | "default"
        | "error"
        | "warning"
        | "info"
        | "primary";
    } = {
      active: "success",
      inactive: "default",
      relationship: "info",
      lost: "error",
      deceased: "warning",
      prospect: "primary",
    };
    return (
      <Chip
        label={status}
        color={colors[status.toLowerCase()] || "default"}
        size="small"
      />
    );
  };

  switch (customerType) {
    case "PersonAccount":
      return [
        {
          field: "first_name",
          headerName: "First Name",
          width: 150,
          sortable: true,
          renderCell: (params) => (
            <MuiLink
              href={`/dashboard/customers/${params.row.id}`}
              underline="hover"
            >
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "last_name",
          headerName: "Last Name",
          width: 150,
          sortable: true,
          renderCell: (params) => (
            <MuiLink
              href={`/dashboard/customers/${params.row.id}`}
              underline="hover"
            >
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "new_cfs_client_id",
          headerName: "CFS Client ID",
          width: 150,
          renderCell: (params) => {
            const { cfs_client_id, new_cfs_client_id } = params.row;
            return new_cfs_client_id || cfs_client_id || ""; 
          },
        },
        {
          field: "email",
          headerName: "Email",
          width: 250,
          sortable: true,
          renderCell: (params) => (
            <MuiLink href={`mailto:${params.value}`} underline="hover">
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "primary_phone",
          headerName: "Primary Phone",
          width: 150,
          renderCell: (params) => (
            <MuiLink href={createPhoneLink(params.value)} underline="hover">
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "status",
          headerName: "Status",
          width: 150,
          sortable: true,
          renderCell: (params) => renderStatus(params.value),
        },
      ];
    case "Household":
      return [
        {
          field: "full_name",
          headerName: "Name",
          width: 250,
          sortable: true,
          renderCell: (params) => (
            <MuiLink
              href={`/dashboard/customers/${params.row.id}`}
              underline="hover"
            >
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "primaryContact",
          headerName: "Primary Account",
          width: 250,
          renderCell: (params) => {
            const primaryContact = params.row.primaryContact;
            if (primaryContact) {
              return (
                <MuiLink
                  href={`/dashboard/customers/${primaryContact.id}`}
                  underline="hover"
                >
                  {primaryContact.first_name} {primaryContact.last_name}
                </MuiLink>
              );
            } else {
              return "N/A";
            }
          },
        },
        {
          field: "spouseContact",
          headerName: "Spouse Account",
          width: 250,
          renderCell: (params) => {
            const spouseContact = params.row.spouseContact;
            if (spouseContact) {
              return (
                <MuiLink
                  href={`/dashboard/customers/${spouseContact.id}`}
                  underline="hover"
                >
                  {spouseContact.first_name} {spouseContact.last_name}
                </MuiLink>
              );
            } else {
              return "N/A";
            }
          },
        },
        {
          field: "new_cfs_client_id",
          headerName: "CFS Client ID",
          width: 150,
          renderCell: (params) => {
            const { cfs_client_id, new_cfs_client_id } = params.row;
            return new_cfs_client_id || cfs_client_id || ""; 
          },
        },
        {
          field: "status",
          headerName: "Status",
          width: 150,
          renderCell: (params) => renderStatus(params.value),
        },
        {
          field: "primaryContactPhone",
          headerName: "Primary Phone",
          width: 200,
          renderCell: (params) => {
            const primaryContact = params.row.primaryContact;
            if (primaryContact && primaryContact.primary_phone) {
              return (
                <MuiLink
                  href={createPhoneLink(primaryContact.primary_phone)}
                  underline="hover"
                >
                  {primaryContact.primary_phone}
                </MuiLink>
              );
            } else {
              return "N/A";
            }
          },
        },
        {
          field: "primaryContactEmail",
          headerName: "Primary Email",
          width: 250,
          sortable: true,
          renderCell: (params) => {
            const primaryContact = params.row.primaryContact;
            if (primaryContact && primaryContact.email) {
              return (
                <MuiLink href={`mailto:${primaryContact.email}`} underline="hover">
                  {primaryContact.email}
                </MuiLink>
              );
            } else {
              return "N/A";
            }
          },
        },
      ];

    case "BusinessClients":
      return [
        {
          field: "full_name",
          headerName: "Name",
          width: 250,
          sortable: true,
          renderCell: (params) => (
            <MuiLink
              href={`/dashboard/customers/${params.row.id}`}
              underline="hover"
            >
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "primaryContact",
          headerName: "Primary Account",
          width: 250,
          renderCell: (params) => {
            const primaryContact = params.row.primaryContact;
            if (primaryContact) {
              return (
                <MuiLink
                  href={`/dashboard/customers/${primaryContact.id}`}
                  underline="hover"
                >
                  {primaryContact.first_name} {primaryContact.last_name}
                </MuiLink>
              );
            } else {
              return "N/A";
            }
          },
        },
        { field: "cfs_client_id", headerName: "CFS Client ID", width: 150 },
        {
          field: "status",
          headerName: "Status",
          width: 150,
          renderCell: (params) => renderStatus(params.value),
        },
        {
          field: "primaryContactPhone",
          headerName: "Primary Phone",
          width: 200,
          renderCell: (params) => {
            const primaryContact = params.row.primaryContact;
            if (primaryContact && primaryContact.primary_phone) {
              return (
                <MuiLink
                  href={createPhoneLink(primaryContact.primary_phone)}
                  underline="hover"
                >
                  {primaryContact.primary_phone}
                </MuiLink>
              );
            } else {
              return "N/A";
            }
          },
        },
        {
          field: "primaryContactEmail",
          headerName: "Primary Email",
          width: 250,
          sortable: true,
          renderCell: (params) => {
            const primaryContact = params.row.primaryContact;
            if (primaryContact && primaryContact.email) {
              return (
                <MuiLink href={`mailto:${primaryContact.email}`} underline="hover">
                  {primaryContact.email}
                </MuiLink>
              );
            } else {
              return "N/A";
            }
          },
        },
      ];
    case "Institution":
      return [
        {
          field: "full_name",
          headerName: "Name",
          width: 250,
          sortable: true,
          renderCell: (params) => (
            <MuiLink
              href={`/dashboard/customers/${params.row.id}`}
              underline="hover"
            >
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "primary_phone",
          headerName: "Primary Phone",
          width: 150,
          renderCell: (params) => (
            <MuiLink href={createPhoneLink(params.value)} underline="hover">
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "email",
          headerName: "Email",
          width: 250,
          sortable: true,
          renderCell: (params) => (
            <MuiLink href={`mailto:${params.value}`} underline="hover">
              {params.value}
            </MuiLink>
          ),
        },
        {
          field: "ein",
          headerName: "EIN",
          width: 250,
          sortable: true,
        },
        {
          field: "address",
          headerName: "Address",
          width: 300,
          renderCell: (params) => {
            const address = params.value;
            if (!address) return "";

            const { street, city, state, postalCode, country } = address;
            return `${street}, ${city}, ${state} ${postalCode}, ${country}`;
          },
        },
      ];
    default:
      return [
        {
          field: "full_name",
          headerName: "Name",
          width: 250,
          sortable: true,
          renderCell: (params) => (
            <MuiLink
              href={`/dashboard/customers/${params.row.id}`}
              underline="hover"
            >
              {params.value}
            </MuiLink>
          ),
        },
      ];
  }
};

export default Customers;
