import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Switch,
  Link,
} from "@mui/material";
import { Grade, Search as SearchIcon } from "@mui/icons-material";
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import useDebounce from "../hooks/useDebounce";
import api from "../utils/api";

interface TimeEntry {
  id: string;
  spent_date: string;
  hours: number;
  billable: boolean;
  task: { name: string } | null;
  user: { name: string } | null;
  client: { name: string } | null;
}

interface Project {
  id: string;
  name: string;
  clientName: string;
  totalHours: number;
  totalBillableHours: number;
  mostRecentEntry: { spent_date: string } | null;
  timeEntries: TimeEntry[];
}

const HarvestProjectManagement: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  // Filters
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "ends_on", sort: "asc" },
  ]);

  const [billable, setBillable] = useState<string | undefined>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);
  // Snackbar for error messages
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filterEndsOn, setFilterEndsOn] = useState<boolean>(true);

  // Pagination
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  useEffect(() => {
    let isMounted = true;
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const queryParams: any = {
          searchTerm: debouncedSearchTerm,
          page: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
          sortField: "ends_on",
          sortOrder: "ASC",
          filterEndsOn,
        };

        if (sortModel.length > 0) {
          queryParams.sortField = sortModel[0].field;
          queryParams.sortOrder = sortModel[0].sort?.toUpperCase();
        }

        if (startDate && endDate) {
          queryParams.dateRange = [
            startDate.startOf("day").toISOString(),
            endDate.endOf("day").toISOString(),
          ];
        }

        if (billable) {
          queryParams.billable = billable === "true";
        }

        const response = await api.get("api/service/harvest/time-entries/all", {
          params: queryParams,
        });

        if (isMounted && response.data) {
          setProjects(response.data.records || []);
          setTotal(response.data.total || 0);
        }
      } catch (error) {
        if (isMounted) {
          setErrorMessage("Failed to fetch projects");
          setOpenSnackbar(true);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchProjects();
    return () => {
      isMounted = false;
    };
  }, [
    debouncedSearchTerm,
    startDate,
    endDate,
    sortModel,
    billable,
    paginationModel,
    filterEndsOn,
  ]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    setEndDate(date);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (
      model.length !== sortModel.length ||
      (model.length > 0 && model[0].field !== sortModel[0]?.field) ||
      (model.length > 0 && model[0].sort !== sortModel[0]?.sort)
    ) {
      setSortModel(model);
    }
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleProjectClick = (project: Project) => {
    setSelectedProject(project);
  };

  const handleBackToProjects = () => {
    setSelectedProject(null);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const projectColumns: GridColDef[] = [
    
    { field: "name", headerName: "Project Name", width: 150 },
    {
      field: "totalHours",
      headerName: "Total Hours",
      width: 150,
      renderCell: (params: any) => `${params.value || "0.00"} hours`,
    },
    {
      field: "totalBillableCost",
      headerName: "Total Billable Cost",
      width: 150,
      renderCell: (params: any) => `$${params.value || "0.00"}`,
    },
    {
      field: "ends_on",
      headerName: "Due Date",
      width: 150,
      renderCell: (params: any) =>
        params.value ? dayjs(params.value).format("DD/MM/YYYY") : "No Entries",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params: any) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => handleProjectClick(params.row)}
        >
          View Time Entries
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Project Link",
      width: 200,
      renderCell: (params) => (
        <Link
        href={`https://cuddyfinancialservices.harvestapp.com/projects/${params.value}`}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        sx={{ color: "primary.main", fontWeight: "bold" }}
      >
        View Project
      </Link>
      ),
    },
  ];

  const timeEntryColumns: GridColDef[] = [
    {
      field: "spent_date",
      headerName: "Date",
      width: 120,
      renderCell: (params: any) =>
        params.row.spent_date
          ? dayjs(params.row.spent_date).format("DD/MM/YYYY")
          : "N/A",
    },
    {
      field: "hours",
      headerName: "Hours",
      width: 100,
    },
    {
      field: "billable",
      headerName: "Billable",
      width: 100,
      renderCell: (params: any) => (params.row.billable ? "Yes" : "No"),
    },
    {
      field: "billable_rate",
      headerName: "Billable Rate",
      width: 100,
      renderCell: (params: any) => `$${params.value || "0.00"}`,
    },
    {
      field: "task",
      headerName: "Task",
      flex: 1,
      renderCell: (params: any) => params.row.task?.name || "N/A",
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      renderCell: (params: any) => params.row.user?.name || "N/A",
    },
    {
      field: "client",
      headerName: "Client",
      flex: 1,
      renderCell: (params: any) => params.row.client?.name || "N/A",
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          <CircularProgress />
        </Box>
      ) : selectedProject ? (
        /* Displaying time entries for the selected project */
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Typography variant="h5" gutterBottom>
              Time Entries for Project: {selectedProject.name}
            </Typography>
            <Button variant="contained" onClick={handleBackToProjects}>
              Back to Projects
            </Button>
          </Box>
          <Box sx={{ padding: 2 }}>
            <div style={{ height: 600, width: "100%" }}>
              {selectedProject.timeEntries.length > 0 ? (
                <DataGrid
                  rows={selectedProject.timeEntries}
                  columns={timeEntryColumns}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  pageSizeOptions={[10, 25, 50]}
                  getRowId={(row) => row.id}
                  disableRowSelectionOnClick
                />
              ) : (
                <Typography>
                  No time entries available for this project.
                </Typography>
              )}
            </div>
          </Box>
        </Box>
      ) : (
        /* Displaying the project list with filters */
        <Box>
          <Typography variant="h5" gutterBottom>
            Project List
          </Typography>
          {/* Filters and Search */}
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              {/* Search Input */}
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search by Project Name"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ border: "none" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={filterEndsOn}
                    onChange={(e) => setFilterEndsOn(e.target.checked)}
                    color="primary"
                  />
                }
                label="Show Only Future Projects"
              />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ padding: 2 }}>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={projects || []}
                columns={projectColumns}
                paginationModel={paginationModel}
                rowCount={total}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={[10, 25, 50]}
                disableRowSelectionOnClick
                paginationMode="server"
                sortModel={sortModel}
                disableColumnSorting
              />
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HarvestProjectManagement;
